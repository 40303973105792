import React, { useEffect, useState, useCallback } from "react";
import { Grid, Box, Typography, Divider } from "@mui/material";
import { useDrop } from "react-dnd";
import moment from "moment";

import * as Api from "../api";

import { connect } from "react-redux";
import { updateFloorTableList } from "../redux/actions/userDataActions";

import Loader from "./Loader";
import AlertMsg from "./AlertMsg";
import ConfirmAlert from "./ConfirmAlert";
import AlertPopUp from "./AlertPopUp";
import TableSetupType from "./TableSetupType";
import TableSetupData from "./TableSetupData";
import AddFloorNameModal from "./AddFloorNameModal";

import styles from "./FloorTableSetup.module.css";

import { ReactComponent as AddIcon } from "../assets/images/add.svg";
import { ReactComponent as EditIcon } from "../assets/images/pencil.svg";

import { DINEIN, NAVBAR_HEIGHT } from "../constants";
import { THEME_MODE } from "../constants/Theme";
import { drawerWidth } from "../components/Sidebar";

let CONTENT_HEIGHT = window.innerHeight - NAVBAR_HEIGHT;
let FLOOR_LIST_HEIGHT = 50;
export let TABLE_WIDTH = 1980;
export let TABLE_HEIGHT = 1080;

const tableStructureList = [
  {
    type: "rectangle",
    top: 0,
    left: 0,
    width: 80,
    height: 100,
    angle: 0,
    name: 0,
    cover: 0,
  },
  {
    type: "circle",
    top: 0,
    left: 0,
    width: 100,
    height: 100,
    angle: 0,
    name: 0,
    cover: 0,
  },
];

export let uiStyle = {
  TableOrders: {
    floor: "#0E141B",
    table: "#323F4E",
    chair: "#111A24",
    activeFloor: "#313E53",
    activeTable: "#43ACE3",
    activeChair: "#256384",
    table1: "#323F4E",
    bg: "#01060B",
    mainBg: "#01060B",
  },
  Settings: {
    floor: "#0E141B",
    table: "#323F4E",
    chair: "#2C3844",
    activeFloor: "#313E53",
    activeTable: "#43ACE3",
    activeChair: "#256384",
    table1: "#425161",
    bg: "#10161D",
    mainBg: "#000000",
  },
};

const FloorTableSetup = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];

  const [isLoading, setIsLoading] = useState(true);
  const [tableStructure, setTableStructure] = useState(tableStructureList);
  const [tableList, setTableList] = useState([]);
  const [editable, setEditable] = useState(props.tableEditable);
  const [leftViewWidth, setLeftViewWidth] = useState(300);
  const [rightViewWidth, setRightViewWidth] = useState(500);
  const [selectedFloor, setSelectedFloor] = useState(null);
  const [floorList, setFloorList] = useState([]);
  const [addFloorModalVisible, setAddFloorModalVisible] = useState(false);
  const [editFloorData, setEditFloorData] = useState(null);
  const [tableSetupWidth, setTableSetupWidth] = useState(300);
  const [tableSetupHeight, setTableSetupHeight] = useState(CONTENT_HEIGHT);
  const [confirmAlertVisible, setConfirmAlertVisible] = useState(null);
  const [alertPopUpVisible, setAlertPopUpVisible] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [msgAlert, setMsgAlert] = useState({
    open: false,
    message: "",
    msgType: "error",
  });

  useEffect(() => {
    setFloorList(props.floorList);
    if (props.floorList.length) {
      let orders = [...props.orderList];
      let floor_list = [];
      props.floorList.map((y) => {
        let table = [];
        y.tableDetails.map((z) => {
          let orderData = null;
          let findIndex = orders.findIndex(
            (o) =>
              o?._idRestaurantTable && o?._idRestaurantTable?.includes(z._id)
          );
          if (findIndex >= 0) {
            orderData = orders[findIndex];
          } else {
            let orders1 = [...props.diningDetailTableOrders];
            let index1 = orders1.findIndex((x) =>
              x._idRestaurantTable.includes(z._id)
            );
            if (index1 >= 0) {
              orderData = orders1[index1];
            }
          }
          if (orderData) {
            let course_name = null;
            let cart_course = [];
            let course_id = orderData?.courseAway;
            cart_course = props.restaurantCourseList.filter(
              (x) => x._id == course_id
            );
            if (cart_course.length) {
              if (cart_course[0]?.name) {
                course_name = cart_course[0]?.name;
              }
            }

            table.push({
              ...z,
              status: orderData?.["orderStatus"]
                ? orderData?.["orderStatus"]
                : "pending",
              mins: orderData?.["createdAt"]
                ? moment(orderData["createdAt"]).fromNow()
                : "",
              courseAway: course_name,
            });
          } else {
            table.push(z);
          }
        });
        floor_list.push({
          ...y,
          tableDetails: table,
        });
      });
      setFloorList(floor_list);
      setSelectedFloor(floor_list[0]);
      setTableList(floor_list[0]?.tableDetails);
    } else {
      setSelectedFloor(null);
      setTableList([]);
    }
  }, [props.floorList, props.orderList]);

  useEffect(() => {
    setIsLoading(props.isLoading);
  }, [props.isLoading]);

  useEffect(() => {
    let dragHeight = tableSetupHeight * 0.2;
    let list = [...tableStructureList];
    let data = [];
    list.map((x) => {
      let dragWidth = x?.type == "rectangle" ? dragHeight - 20 : dragHeight;
      let w = dragWidth * (TABLE_WIDTH / tableSetupWidth);
      let h = dragHeight * (w / dragWidth);
      data.push({
        ...x,
        width: w,
        height: h,
      });
    });
    setTableStructure(data);
  }, [tableSetupWidth]);

  useEffect(() => {
    if (!props.isLoading && editable) {
      let list = [...floorList];
      let index = list.findIndex((z) => z._id == selectedFloor?._id);
      if (index >= 0) {
        list[index]["tableDetails"] = tableList;
      }
      setFloorList(list);
    }
  }, [tableList]);

  useEffect(() => {
    if (document.getElementById("tableSetUpLeftViewId")) {
      setLeftViewWidth(
        document.getElementById("tableSetUpLeftViewId").offsetWidth
      );
    }
    if (document.getElementById("tableSetUpRightViewId")) {
      setRightViewWidth(
        document.getElementById("tableSetUpRightViewId").offsetWidth
      );
    }
  }, [editable, props.drawerOpen]);

  useEffect(() => {
    if (document.getElementById("tableSetupPlatformId")) {
      let w = document.getElementById("tableSetupPlatformId").offsetWidth;
      setTableSetupWidth(w);
      if (props.drawerOpen && w == tableSetupWidth) {
        setTableSetupWidth(w - drawerWidth);
      }
      setTableSetupHeight(
        document.getElementById("tableSetupPlatformId").offsetHeight
      );
    }
  }, [editable, props.drawerOpen]);

  const onSelectFloor = (item) => {
    setSelectedFloor(item);
    let list = item?.tableDetails;
    setTableList(list);
  };

  function isNumeric(value) {
    return /^-?\d+$/.test(value);
  }

  const addBox = useCallback(
    (type, left, top) => {
      let data = JSON.parse(JSON.stringify(tableStructure)).filter(
        (a) => a.type == type
      );

      let top1 =
        (top - (NAVBAR_HEIGHT + FLOOR_LIST_HEIGHT + 50)) *
        (TABLE_HEIGHT / tableSetupHeight);
      let left1 = (left - leftViewWidth - 40) * (TABLE_WIDTH / tableSetupWidth);

      if (props.drawerOpen) {
        left1 =
          (left - leftViewWidth - drawerWidth - 40) *
          (TABLE_WIDTH / tableSetupWidth);
      }

      let name = data[0]["name"];
      if (name == 0) {
        let tbl_list = [];
        floorList.map((tbl) => {
          let tbls = tbl.tableDetails
            .filter((y) => isNumeric(y.name))
            .map((y) => y.name);
          tbl_list = [...tbl_list, ...tbls];
        });
        tbl_list.sort(function (a, b) {
          return a - b;
        });
        if (tbl_list.length) {
          let num = tbl_list[tbl_list.length - 1];
          num = Number(num);
          name = num + 1;
        } else {
          name = 1;
        }
      }

      data[0]["_id"] = new Date();
      data[0]["left"] = left1;
      data[0]["top"] = top1;
      data[0]["name"] = name;
      onCreateTable(data[0]);
    },
    [
      tableList,
      setTableList,
      tableStructure,
      tableSetupHeight,
      tableSetupWidth,
      leftViewWidth,
    ]
  );

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: "Box",
      drop(item, monitor) {
        const delta = monitor.getClientOffset();
        const left = Math.round(delta.x);
        const top = Math.round(delta.y);
        addBox(item.type, left, top);
        return undefined;
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    }),
    [addBox]
  );

  const onDelete = (id) => {
    setConfirmAlertVisible(id);
  };

  const onDrag = (x, y, id) => {
    let list = [...tableList];
    let index = list.findIndex((z) => z._id == id);
    if (index >= 0) {
      list[index]["left"] = x;
      list[index]["top"] = y;
    }
    setTableList(list);
  };

  const onResize = (w, h, x, y, id) => {
    let list = [...tableList];
    let index = list.findIndex((z) => z._id == id);
    if (index >= 0) {
      let scale = Math.min(
        tableSetupHeight / TABLE_HEIGHT,
        tableSetupWidth / TABLE_WIDTH
      );
      let w1 = w / scale;
      let h1 = h * (w1 / w);
      list[index]["width"] = w1;
      list[index]["height"] = h1;
      list[index]["left"] = x;
      list[index]["top"] = y;
    }
    setTableList(list);
  };

  const onRotate = (val, id) => {
    let list = [...tableList];
    let index = list.findIndex((z) => z._id == id);
    if (index >= 0) {
      list[index]["angle"] = val;
    }
    setTableList(list);
  };

  const onTableClick = (table_id) => {};

  const onSave = (data, id) => {
    let list = [...tableList];
    let index = list.findIndex((z) => z._id == id);
    if (index >= 0) {
      list[index]["name"] = data.name;
      list[index]["cover"] = data.cover;
    }
    setTableList(list);
  };

  const onAddFloorSubmit = (data) => {
    let list = [...floorList];
    const findIndex = list.findIndex((x) => x?._id === data?._id);
    if (findIndex >= 0) {
      list[findIndex] = { ...list[findIndex], ...data };
    } else {
      list.push(data);
    }
    setFloorList(list);
    props.updateFloorTableList(JSON.parse(JSON.stringify(list)));
    setEditFloorData(null);
    setAddFloorModalVisible(false);
  };

  const onAddFloorBtnClick = () => {
    setEditFloorData(null);
    setAddFloorModalVisible(true);
  };

  const onEditFloorName = (item) => {
    setEditFloorData(item);
    setAddFloorModalVisible(true);
  };

  const onCloseAddFloorModal = () => {
    setEditFloorData(null);
    setAddFloorModalVisible(false);
  };

  const onSaveTableSetup = () => {
    setIsLoading(true);
    let results = [];
    let floor_list = [];
    floorList.map((y) => {
      let table_detail = [];
      y.tableDetails.map((z) => {
        let data = {
          _id: z._id,
          _idRestaurant: props.restaurantId,
          _idRestaurantFloors: y._id,
          name: z.name,
          type: z.type,
          top: z.top,
          left: z.left,
          width: z.width,
          height: z.height,
          angle: z.angle,
          cover: z.cover,
        };
        table_detail.push(data);
        results.push(Api.updateRestaurantTables(data, data._id));
      });
      let data1 = {
        ...y,
        tableDetails: table_detail.sort(function (a, b) {
          return a.name - b.name;
        }),
      };
      floor_list.push(data1);
    });

    Promise.all(results)
      .then(([stats, info]) => {
        setIsLoading(false);
        if (info.success == false || stats.success == false) {
          setMsgAlert({
            open: true,
            message: stats.success == false ? stats.msg : info.msg,
            msgType: "error",
          });
        } else {
          props.updateFloorTableList(floor_list);
          setMsgAlert({
            open: true,
            message: "Updated Successfully",
            msgType: "success",
          });
        }
      })
      .catch((error) => console.log(error));
  };

  const onCreateTable = (data) => {
    if (selectedFloor == null) {
      return;
    }

    let tables = [];
    tables.push({
      _idRestaurant: props.restaurantId,
      _idRestaurantFloors: selectedFloor._id,
      name: data.name,
      type: data.type,
      top: data.top,
      left: data.left,
      width: data.width,
      height: data.height,
      angle: data.angle,
      cover: data.cover,
    });

    setTableLoading(true);
    let data2 = JSON.stringify(tables);
    Api.createRestaurantTables(data2).then((response1) => {
      if (response1.success) {
        let tableData = response1.data.rows;
        let tables_list = tableData.map((z) => {
          return {
            ...z,
            type: z.type == null ? "rectangle" : z.type,
            top: z.top == null ? 0 : Number(z.top),
            left: z.left == null ? 0 : Number(z.left),
            width: z.width == null ? 100 : Number(z.width),
            height: z.height == null ? 100 : Number(z.height),
            angle: z.angle == null ? 0 : Number(z.angle),
            cover: z.cover == null ? 0 : z.cover,
          };
        });

        let floor_list = [...JSON.parse(JSON.stringify(props.floorTableList))];
        let floorIndex = floor_list.findIndex(
          (z) => z._id == selectedFloor?._id
        );
        if (floorIndex >= 0) {
          floor_list[floorIndex]["tableDetails"] = [
            ...tableList,
            ...tables_list,
          ].sort(function (a, b) {
            return a.name - b.name;
          });
        }
        props.updateFloorTableList(floor_list);

        setTableList((list1) => [...list1, ...tables_list]);
        setTableLoading(false);
      } else {
        setTableLoading(false);
        setMsgAlert({
          open: true,
          message: response1.msg,
          msgType: "error",
        });
      }
    });
  };

  const onDeleteTable = () => {
    setIsLoading(true);
    let table_list = tableList
      .filter((y) => y._id == confirmAlertVisible)
      .map((x) => x._id);

    let filter =
      "pageSize=30000&pageNum=1&filter_orderStatus=" +
      '["placed_order", "preparing", "pending_payment"]';
    filter = filter + "&filter_orderType=[" + DINEIN + "]";
    Api.getOrders(props.restaurantId, filter).then((response) => {
      if (response.success) {
        let orders = response.data.rows;
        let filterOrder = orders.filter((x) =>
          (x._idRestaurantTable?.length ? x._idRestaurantTable : []).some((t) =>
            table_list.includes(t)
          )
        );

        if (filterOrder.length == 0) {
          let data = JSON.stringify({ tableIds: table_list });
          Api.deleteRestaurantTables(data).then((response) => {
            if (response.success) {
              let list = [...tableList];
              let index = list.findIndex((x) => x._id == confirmAlertVisible);
              if (index >= 0) {
                list.splice(index, 1);
              }
              setConfirmAlertVisible(null);
              setTableList(list);

              let floor_list = [
                ...JSON.parse(JSON.stringify(props.floorTableList)),
              ];
              let floorIndex = floor_list.findIndex(
                (z) => z._id == selectedFloor?._id
              );
              if (floorIndex >= 0) {
                floor_list[floorIndex]["tableDetails"] = floor_list[floorIndex][
                  "tableDetails"
                ].filter((x) => x._id != confirmAlertVisible);
              }
              props.updateFloorTableList(floor_list);
            } else {
              setMsgAlert({
                open: true,
                message: response.msg,
                msgType: "error",
              });
            }
            setIsLoading(false);
          });
        } else {
          setAlertPopUpVisible(true);
          setConfirmAlertVisible(null);
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    });
  };

  return (
    <>
      <Grid
        id={"tableSetUp"}
        container
        item
        md={12}
        xs={12}
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
        className={styles.mainView}
        style={{
          height: CONTENT_HEIGHT,
          backgroundColor: uiStyle[props.page]["mainBg"],
        }}
      >
        {editable ? (
          <Grid
            item
            xs={3}
            id={"tableSetUpLeftViewId"}
            className={styles.leftView}
            pt={1}
            pb={1}
            pl={0.5}
            pr={1}
          >
            <Box
              p={1}
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
              justifyContent={"center"}
              className={styles.leftSubView}
              style={{
                backgroundColor: uiStyle[props.page]["bg"],
              }}
            >
              <Typography className={styles.newElementText}>
                New Elements
              </Typography>
              <Typography className={styles.dragAndDropText} mt={0.5}>
                Drag and drop to add table
              </Typography>

              <Box className={styles.borderBottomLine} mt={2} />

              {tableStructure.map((item, index) => {
                return (
                  <Grid
                    key={index}
                    item
                    md={12}
                    xs={12}
                    mt={1}
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <TableSetupType
                      data={item}
                      tableHeight={tableSetupHeight}
                      page={props.page}
                      uiStyle={uiStyle}
                    />
                    <Box mt={1.5}>
                      <Typography className={styles.shapeText}>
                        {item.type == "rectangle"
                          ? "Rectangular"
                          : item.type == "circle"
                          ? "Circular"
                          : "Square"}{" "}
                        Table
                      </Typography>
                    </Box>
                  </Grid>
                );
              })}
            </Box>
          </Grid>
        ) : null}
        <Grid
          item
          xs={editable ? 9 : 12}
          pt={1}
          pb={1}
          pr={0.5}
          display={"flex"}
          flexDirection={"column"}
          id={"tableSetUpRightViewId"}
        >
          <Box
            display={"flex"}
            flexDirection={"row"}
            sx={{ width: tableSetupWidth }}
          >
            <Box
              className={styles.floorView}
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              sx={{
                width: "100%",
                height: FLOOR_LIST_HEIGHT,
                backgroundColor: uiStyle[props.page]["floor"],
              }}
            >
              {floorList.map((item, index) => {
                return (
                  <>
                    <Box
                      key={index}
                      onClick={() => onSelectFloor(item)}
                      px={3}
                      style={{
                        minWidth: 150,
                        height: "100%",
                        cursor: "pointer",
                        ...(selectedFloor?._id == item?._id
                          ? {
                              backgroundColor:
                                uiStyle[props.page]["activeFloor"],
                            }
                          : { backgroundColor: uiStyle[props.page]["floor"] }),
                      }}
                      sx={{
                        path: { stroke: "#FFF" },
                      }}
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <Typography className={styles.floorText} mr={1}>
                        {item.name}
                      </Typography>
                      <EditIcon
                        width={15}
                        height={15}
                        onClick={() => onEditFloorName(item)}
                      />
                    </Box>
                    {index < floorList.length - 1 ? (
                      <Divider
                        key={index + "i"}
                        orientation={"vertical"}
                        sx={{
                          "&.MuiDivider-root": {
                            borderColor: theme_mode.borderBottom4,
                            height: "70%",
                          },
                        }}
                      />
                    ) : null}
                  </>
                );
              })}
            </Box>
            {editable ? (
              <Box sx={{ ml: 1 }}>
                <Box
                  className={styles.addFloorBtn}
                  sx={{ backgroundColor: uiStyle[props.page]["activeFloor"] }}
                  onClick={onAddFloorBtnClick}
                >
                  <AddIcon width={20} height={20} />
                </Box>
              </Box>
            ) : null}
            {editable ? (
              <Box ml={1}>
                <Box className={styles.saveBtnView} onClick={onSaveTableSetup}>
                  <Typography className={styles.saveBtnViewText}>
                    Save
                  </Typography>
                </Box>
              </Box>
            ) : null}
          </Box>
          <Grid
            id={"tableSetupPlatformId"}
            className={styles.rightView}
            sx={{ backgroundColor: uiStyle[props.page]["bg"] }}
            ref={drop}
            mt={1}
          >
            {tableLoading ||
            (isLoading &&
              !confirmAlertVisible &&
              confirmAlertVisible == null) ? (
              <Loader />
            ) : (
              tableList.map((item, index) => {
                return (
                  <TableSetupData
                    key={item._id}
                    item={item}
                    rightViewWidth={rightViewWidth}
                    editable={editable}
                    tableWidth={tableSetupWidth}
                    tableHeight={tableSetupHeight}
                    uiStyle={uiStyle}
                    page={props.page}
                    onResize={onResize}
                    onDrag={onDrag}
                    onRotate={onRotate}
                    onDelete={onDelete}
                    onSave={onSave}
                    onTableClick={onTableClick}
                  />
                );
              })
            )}
          </Grid>
        </Grid>
        <AddFloorNameModal
          editData={editFloorData}
          modalVisible={addFloorModalVisible}
          restaurantId={props.restaurantId}
          themeMode={props.themeMode}
          onSubmit={onAddFloorSubmit}
          onClose={onCloseAddFloorModal}
        />
        <ConfirmAlert
          confirmDelete={confirmAlertVisible != null}
          title={"Are you sure you want to remove this table?"}
          titleStyle={{ fontFamily: "InterMedium !important" }}
          firstBtn={"No"}
          secondBtn={isLoading ? "Please Wait" : "Yes"}
          btnLoading={isLoading}
          onCloseConfirmDelete={() => setConfirmAlertVisible(null)}
          onConfirmDelete={onDeleteTable}
          themeMode={props.themeMode}
        />
      </Grid>
      <AlertPopUp
        modalVisible={alertPopUpVisible}
        msg={"Tables have active orders"}
        themeMode={props.themeMode}
        onClick={() => setAlertPopUpVisible(false)}
      />
      <AlertMsg
        msgAlert={msgAlert}
        onCloseAlertMsg={() =>
          setMsgAlert({ open: false, message: "", msgType: "error" })
        }
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    restaurantId: state.userData.restaurantId,
    themeMode: state.userData.themeMode,
    drawerOpen: state.userData.drawerOpen,
    floorTableList: state.userData.floorTableList,
    restaurantCourseList: state.userData.restaurantCourseList,
    diningDetailTableOrders: state.userData.diningDetailTableOrders,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateFloorTableList: (data) => dispatch(updateFloorTableList(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FloorTableSetup);
