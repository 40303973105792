import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";

import styles from "./Printers.module.css";

import * as Api from "../../api";

import { connect } from "react-redux";
import { updateActivePrinter } from "../../redux/actions/userDataActions";

import Container from "../../components/Container";
import Loader from "../../components/Loader";
import DiningConfirmAlert from "../../components/DiningConfirmAlert";
import AlertMsg from "../../components/AlertMsg";
import PrinterTableList from "../../components/PrinterTableList";
import AddPrinterModal from "../../components/AddPrinterModal";

import { ReactComponent as BackBtnIcon } from "../../assets/images/back_btn.svg";

import { FOOTER_XPADDING } from "../../constants";
import { THEME_MODE } from "../../constants/Theme";

const Printers = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [printerList, setPrinterList] = useState([]);
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [editData, setEditData] = useState(null);
  const [alertConfirmModalData, setAlertConfirmModalData] = useState(null);
  const [msgAlert, setMsgAlert] = useState({
    open: false,
    message: "",
    msgType: "error",
  });

  useEffect(() => {
    getPrinterList();
  }, []);

  const getPrinterList = () => {
    setLoading(true);
    Api.getPrinterList(props.restaurantId).then((response) => {
      if (response.success) {
        let list = response.data.rows;
        setPrinterList(list);
      } else {
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
      setLoading(false);
    });
  };

  const toggleAddPrinterModalVisible = (visible) => {
    setEditData(null);
    setAddModalVisible(visible);
  };

  const onSubmitAddPrinter = (data) => {
    if (editData) {
      let list = [...printerList];
      let findIndex = list.findIndex((x) => x._id == editData._id);
      if (findIndex >= 0) {
        list[findIndex] = { ...list[findIndex], ...data };
        if (props.activePrinter?._id == editData._id) {
          props.updateActivePrinter(list[findIndex]);
        }
        setPrinterList(list);
      }
    } else {
      setPrinterList([...printerList, data]);
    }
    setEditData(null);
    setAddModalVisible(false);
  };

  const onEditPrinter = (data) => {
    setEditData(data);
    setAddModalVisible(true);
  };

  const onDeletePrinter = (data) => {
    setAlertConfirmModalData(data);
  };

  const toggleConfirmModal = (data) => {
    setAlertConfirmModalData(data);
  };

  const onConfirmDelete = () => {
    setLoading(true);
    Api.deletePrinter(alertConfirmModalData._id).then((response) => {
      if (response.success) {
        setPrinterList(
          printerList.filter((x) => x._id != alertConfirmModalData._id)
        );
        if (props.activePrinter?._id == alertConfirmModalData._id) {
          props.updateActivePrinter(null);
        }
        setAlertConfirmModalData(null);
        setMsgAlert({
          open: true,
          message: "Deleted Successfully",
          msgType: "success",
        });
      } else {
        setMsgAlert({
          open: true,
          message: response.msg,
          msgType: "error",
        });
      }
      setLoading(false);
    });
  };

  return (
    <Container
      page={"settings"}
      navTitle={"Printers"}
      p={1.5}
      fpx={FOOTER_XPADDING[props.themeMode]}
      fpy={0}
    >
      {loading ? (
        <Loader />
      ) : (
        <Grid
          p={1.5}
          style={{ backgroundColor: theme_mode.card3, borderRadius: 15 }}
        >
          <Grid mb={2} container item xs={12}>
            <Grid item xs={5}>
              <Box
                onClick={() => navigate("/settings")}
                sx={{ cursor: "pointer", width: "fit-content" }}
              >
                <BackBtnIcon width={33} height={33} />
              </Box>
            </Grid>
            <Grid container item xs={7} justifyContent={"flex-end"}>
              <Box
                className={styles.addBtnView}
                sx={{
                  backgroundColor: theme_mode.black,
                  border: `1.5px solid ${theme_mode.border7}`,
                }}
                onClick={() => toggleAddPrinterModalVisible(true)}
              >
                <Typography
                  className={styles.addBtnText}
                  sx={{
                    color: theme_mode.btnText,
                  }}
                  ml={0.5}
                >
                  Add Printer
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid>
            <PrinterTableList
              themeMode={props.themeMode}
              itemList={printerList}
              coursesList={props?.restaurantCourseList}
              floorList={props?.floorTableList}
              editItem={onEditPrinter}
              deleteItem={onDeletePrinter}
              activePrinter={props.activePrinter}
              onActivePrinter={props.updateActivePrinter}
            />
          </Grid>
        </Grid>
      )}
      <AddPrinterModal
        editData={editData}
        modelVisible={addModalVisible}
        restaurantId={props.restaurantId}
        themeMode={props.themeMode}
        coursesList={props?.restaurantCourseList}
        floorList={props?.floorTableList}
        onSubmit={onSubmitAddPrinter}
        onToggle={toggleAddPrinterModalVisible}
      />
      <DiningConfirmAlert
        page={"printer"}
        data={alertConfirmModalData}
        themeMode={props.themeMode}
        onClose={() => toggleConfirmModal(null)}
        onDelete={onConfirmDelete}
      />
      <AlertMsg
        msgAlert={msgAlert}
        onCloseAlertMsg={() =>
          setMsgAlert({ open: false, message: "", msgType: "error" })
        }
      />
    </Container>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateActivePrinter: (data) => dispatch(updateActivePrinter(data)),
  };
};

const mapStateToProps = (state) => {
  return {
    restaurantId: state.userData.restaurantId,
    themeMode: state.userData.themeMode,
    activePrinter: state.userData.activePrinter,
    restaurantCourseList: state.userData.restaurantCourseList,
    floorTableList: state.userData.floorTableList,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Printers);
