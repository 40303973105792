import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { connect } from "react-redux";

import "./../assets/css/custom.css";

import { THEME_MODE } from "../constants/Theme";
import {IS_LARGE_SCREEN} from "../constants";

const SettingSelectField = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];
  const styles = styles1(theme_mode);

  const theme = createTheme({
    components: {
      MuiPopover: {
        styleOverrides: {
          root: {
            "& .MuiPaper-root.MuiMenu-paper.MuiPaper-elevation.MuiPaper-rounded.MuiPopover-paper":
              {
                backgroundColor: theme_mode.card,
              },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            "& .MuiSvgIcon-root": {
              color: theme_mode.iconBg2,
            },
          },
        },
      },
    },
    palette: {
      gray: {
        main: theme_mode.inputColor,
      },
    },
  });

  let bgColor =
    props?.editable != false
      ? props?.backgroundColor
        ? props.backgroundColor
        : theme_mode.inputBg
      : theme_mode.inputDisabledBg;

  return (
    <Box>
      {props?.label ? (
        <Box mb={0.8}>
          <Typography
            sx={[styles.labelText, props?.labelStyle ? props.labelStyle : null]}
          >
            {props.label}
          </Typography>
        </Box>
      ) : null}
      <Box
        sx={{
          ...styles.textInputView,
          pl: props?.leftView ? 1.5 : 0,
          pr: props?.rightView ? 1.5 : 0,
          backgroundColor: bgColor,
          ...props.textInputViewStyle,
        }}
      >
        <ThemeProvider theme={theme}>
          <TextField
            select
            required={props?.required ? props.required : false}
            disabled={!(props?.editable == false ? false : true)}
            label={
              props?.placeholder && props.value == "" ? props?.placeholder : ""
            }
            color="gray"
            fullWidth
            variant={props?.variant ? props?.variant : "standard"}
            value={props.value}
            onChange={(e) => {
              props.setValue(e.target.value);
            }}
            sx={{
              "& .MuiSelect-select": {
                height: "100%",
                display:'flex',
                alignItems:'center',
                "&:focus": {
                  backgroundColor: bgColor,
                },
              },
              "& .MuiPaper-root": {
                backgroundColor: theme_mode.card,
              },
              "& .MuiInputBase-root": {
                "&:hover": {
                  backgroundColor: bgColor,
                },
                "&.Mui-focused": {
                  backgroundColor: bgColor,
                },
                "&.Mui-disabled": {
                  backgroundColor: bgColor,
                },
                "&:before, :after, :hover:not(.Mui-disabled):before": {
                  borderBottom: 0,
                },
              },
              "& .MuiInputAdornment-positionStart": {
                margin: "0 !important",
              },
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: props?.disabledColor
                  ? `${props?.disabledColor} !important`
                  : theme_mode.inputDisabledText,
              },
              "& .MuiFilledInput-input": {
                paddingTop: "0px !important",
                paddingBottom: "0px !important",
                px: 1.5,
              },
            }}
            InputLabelProps={{
              shrink: true,
              sx: {
                ...styles.inputLabelStyle,
                "& .MuiFormLabel-asterisk": {
                  display: "none",
                },
              },
            }}
            InputProps={{
              autoComplete: "none",
              sx: { ...styles.textInputValue, backgroundColor: bgColor },
              startAdornment: props?.leftView ? (
                <InputAdornment
                  position="start"
                  sx={{
                    path: {
                      fill: theme_mode.iconBg2,
                    },
                  }}
                >
                  {props.leftView}
                </InputAdornment>
              ) : null,
              endAdornment: props?.rightView ? (
                <InputAdornment
                  position="end"
                  sx={{
                    path: {
                      fill: theme_mode.iconBg2,
                    },
                  }}
                >
                  {props.rightView}
                </InputAdornment>
              ) : null,
            }}
          >
            {props.menuList.map((x, i) => {
              return (
                <MenuItem
                  key={i}
                  sx={styles.menuItem}
                  divider={i < props.menuList.length - 1}
                  value={x.value}
                >
                  {x.label}
                </MenuItem>
              );
            })}
          </TextField>
        </ThemeProvider>
      </Box>
      <Box>
        {props?.error ? (
          <Typography sx={styles.errorText}>{props.error}</Typography>
        ) : null}
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    themeMode: state.userData.themeMode,
  };
};

export default connect(mapStateToProps, null)(SettingSelectField);

const styles1 = (Theme) => ({
  labelText: {
    color: Theme.inputLabelText,
    fontSize: 15,
    fontFamily: "InterSemiBold",
  },
  textInputView: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderRadius: "12px",
    overflow: "hidden",
  },
  inputLabelStyle: {
    color: Theme.inputLabel,
    fontSize: IS_LARGE_SCREEN ? 22 : 17,
    fontFamily: "InterMedium",
    px: 0,
    mt: 0.8,
  },
  textInputValue: {
    height: IS_LARGE_SCREEN ? 70 : 46,
    color: Theme.inputVal,
    fontSize: 15,
    fontFamily: "InterSemiBold",
    px: 0,
  },
  menuItem: {
    backgroundColor: Theme.card,
    color: Theme.inputVal,
    fontSize: IS_LARGE_SCREEN ? 20 : 15,
    fontFamily: "InterSemiBold",
    "&.MuiMenuItem-divider": {
      borderBottom: "1px solid #DDD",
    },
    "&:hover": {
      backgroundColor: Theme.inputBg2,
      color: Theme.text,
    },
    "&.Mui-selected:hover": {
      backgroundColor: Theme.inputBg2,
      color: Theme.text,
    },
    "&.Mui-selected": {
      backgroundColor: Theme.inputBg5,
      color: Theme.text,
    },
  },
  errorText: {
    color: Theme.redText,
    fontSize: IS_LARGE_SCREEN ? 14 : 10,
    fontFamily: "InterMedium",
  },
});
