import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import moment from "moment";

import * as Api from "../../api";

import { connect } from "react-redux";

import Container from "../../components/Container";
import Loader from "../../components/Loader";
import AlertMsg from "../../components/AlertMsg";
import FromToDatePicker from "../../components/FromToDatePicker";
import ViewedItemsTableList from "../../components/ViewedItemsTableList";
import SettingSelectField from "../../components/SettingSelectField";
import OrderCountGraph from "../../components/OrderCountGraph";

import { ReactComponent as BackBtnIcon } from "../../assets/images/back_btn.svg";

import { THEME_MODE } from "../../constants/Theme";

import {
  ANALYTICS_START_DATE,
  CURRENCY_SYMBOL,
  DELIVERY,
  DINEIN,
  PICKUP,
  TABLE,
} from "../../constants";

let DropdownList = [
  { label: "All Time", value: "all" },
  { label: "This Week", value: "week" },
  { label: "This Month", value: "month" },
  { label: "This Year", value: "year" },
  { label: "Custom Date", value: "custom" },
];

function gcd(a, b) {
  return b === 0 ? a : gcd(b, a % b);
}

const ViewItems = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [dateFilter, setDateFilter] = useState("all");
  const [viewedItemFullList, setViewedItemFullList] = useState([]);
  const [viewedItemList, setViewedItemList] = useState([]);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [error, setError] = useState(null);
  const [orderList, setOrderList] = useState([]);
  const [orderAverageSpend, setOrderAverageSpend] = useState(0);
  const [orderBestSeller, setOrderBestSeller] = useState("");
  const [orderDateCount, setOrderDateCount] = useState([]);
  const [deliverDineInRatio, setDeliverDineInRatio] = useState("");

  const [msgAlert, setMsgAlert] = useState({
    open: false,
    message: "",
    msgType: "error",
  });

  useEffect(() => {
    getViewItems();
    getOrderApi();
  }, []);

  useEffect(() => {
    if (
      (dateFilter === "custom" && (fromDate !== null || toDate !== null)) ||
      dateFilter !== "custom"
    ) {
      const from = fromDate
        ? moment(fromDate, "DD MMM YYYY").format("YYYY-MM-DD")
        : null;
      const to = toDate
        ? moment(toDate, "DD MMM YYYY").format("YYYY-MM-DD")
        : null;
      if (viewedItemFullList.length) {
        getViewItemsData(from, to);
      } else {
        setViewedItemList([]);
      }
    }
  }, [fromDate, toDate, viewedItemFullList]);

  useEffect(() => {
    if (
      (dateFilter === "custom" && (fromDate !== null || toDate !== null)) ||
      dateFilter !== "custom"
    ) {
      if (orderList.length) {
        const from = moment(fromDate, "DD MMM YYYY").format("YYYY-MM-DD");
        const to = moment(toDate, "DD MMM YYYY").format("YYYY-MM-DD");
        const startDate = fromDate ? from : toDate ? to : null;
        const endDate = toDate
          ? to
          : fromDate
          ? from
          : moment().format("YYYY-MM-DD");
        getOrderData(startDate, endDate);
      } else {
        setOrderDateCount([]);
      }
    }
  }, [fromDate, toDate, orderList]);

  const getViewItems = () => {
    setLoading(true);
    let startDate = ANALYTICS_START_DATE;
    let endDate = "today";
    let restId = props.restaurantId;
    const requestBody = {
      dateRanges: [{ startDate, endDate }],
      dimensions: [
        { name: "eventName" },
        { name: "customEvent:restaurant_id" },
        { name: "customEvent:item_id" },
        { name: "date" },
      ],
      metrics: [{ name: "eventCount" }],
      dimensionFilter: {
        andGroup: {
          expressions: [
            {
              filter: {
                fieldName: "eventName",
                inListFilter: {
                  values: ["menu_view"],
                },
              },
            },
            {
              filter: {
                fieldName: "customEvent:restaurant_id",
                inListFilter: {
                  values: [restId],
                },
              },
            },
          ],
        },
      },
      limit: "30000",
      orderBys: [
        {
          desc: true,
          metric: {
            metricName: "eventCount",
          },
        },
      ],
    };
    let data = { requestBody: requestBody };
    data = JSON.stringify(data);
    Api.getAnalytics(data).then((response) => {
      if (response.success) {
        const responseData = response.data;
        let header = responseData.dimensionHeaders;
        let itemIndex = header.findIndex(
          (x) => x.name === "customEvent:item_id"
        );

        let row = responseData?.rows;
        if (row?.length) {
          let list = [];
          if (itemIndex >= 0) {
            row.forEach((x) => {
              let item_id = x?.dimensionValues?.[itemIndex]?.["value"]
                ? x?.dimensionValues?.[itemIndex]?.["value"]
                : null;
              if (item_id) {
                let item_data = props.restaurantMenuList.filter(
                  (x) => x._id === item_id
                );
                let menu = "";
                if (item_data.length) {
                  menu = item_data[0]["name"];
                }

                const dateIndex = header.findIndex((x) => x.name === "date");

                list.push({
                  item_id: item_id,
                  menu: menu,
                  count: x?.metricValues?.[0]?.["value"]
                    ? x.metricValues?.[0]?.["value"]
                    : 0,
                  date:
                    dateIndex >= 0
                      ? moment(
                          x?.dimensionValues?.[dateIndex]?.["value"],
                          ""
                        ).format("YYYYMMDD")
                      : "",
                });
              }
            });
          }
          setViewedItemFullList(list);
        }
      } else {
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
      // setLoading(false);
    });
  };

  const getViewItemsData = (from, to) => {
    setLoading(true);
    const responseData = filterListByTimeRange(
      viewedItemFullList,
      from,
      to,
      "date"
    );

    let list = [];

    responseData.forEach((item) => {
      const fndIndex = list.findIndex((x) => x?.item_id === item?.item_id);
      if (fndIndex >= 0) {
        list[fndIndex] = {
          ...list[fndIndex],
          count: Number(list[fndIndex].count) + Number(item?.count),
        };
      } else {
        list.push(item);
      }
    });

    setViewedItemList(list);
    setLoading(false);
  };

  const getOrderApi = async () => {
    setLoading(true);
    let endDate = moment().add(1, "days").format("YYYY-MM-DD");
    let filter = `pageSize=99999&pageNum=1&filter_orderStatus=["completed", "payment_complete"]&orderBy=createdAt&orderByDir=desc&filter_orderType=[${DINEIN},${TABLE},${DELIVERY},${PICKUP}]`;
    filter += `&filter_dateRangeTo=${endDate}`;

    const response = await Api.getOrders(props.restaurantId, filter);
    if (response.success) {
      setOrderList(response.data.rows);
    } else {
      setMsgAlert({ open: true, message: response.msg, msgType: "error" });
    }

    setLoading(false);
  };

  const getOrderData = async (startDate, endDate) => {
    setLoading(true);
    const data = filterListByTimeRange(
      orderList,
      startDate,
      endDate,
      "createdAt"
    );

    setDeliverDineInRatio(getDeliveryDineInRatio(data));
    setOrderAverageSpend(getOrderAverageSpend(data));

    const menuItemId = getOrderBestSeller(data);
    const menuItemName =
      props.restaurantMenuList.find((x) => x._id === menuItemId)?.name ?? "";
    setOrderBestSeller(menuItemName);

    const graphList = await getOrdersCountGraph(
      data,
      dateFilter,
      startDate,
      endDate
    );
    setOrderDateCount(graphList);

    setLoading(false);
  };

  const filterListByTimeRange = (list, from, to, dateParam) => {
    return dateFilter === "all" || from === null
      ? list
      : list.filter((item) =>
          moment(item?.[dateParam]).isBetween(
            moment(from, "YYYY-MM-DD"),
            moment(to, "YYYY-MM-DD"),
            "days",
            "[]"
          )
        );
  };

  const getDeliveryDineInRatio = (list) => {
    const deliveryCount = list.filter((x) =>
      [DELIVERY, PICKUP].includes(x.orderType)
    );
    const dineInCount = list.filter((x) =>
      [DINEIN, TABLE].includes(x.orderType)
    );

    const col1Sum = deliveryCount.length;
    const col2Sum = dineInCount.length;
    const r = gcd(col1Sum, col2Sum);

    return r > 0 ? `${col1Sum / r}:${col2Sum / r}` : "0:0";
  };

  const getOrderAverageSpend = (list) => {
    const totalAmt = list.reduce((previousValue, currentValue) => {
      return previousValue + currentValue.totalAmount;
    }, 0);

    return list.length ? Number((totalAmt / list.length).toFixed(3)) : 0;
  };

  const getOrderBestSeller = (list) => {
    let orderedItems = [];
    list.forEach((order) => {
      order?.cart?.forEach((cart) => {
        orderedItems.push({
          item: cart?._idMenuItem,
          quantity: cart?.quantity ?? 1,
        });
      });
    });

    const itemCounts = orderedItems?.reduce(
      (acc, o) => ((acc[o.item] = (acc[o.item] || 0) + o.quantity), acc),
      {}
    );

    const filterList = Object.keys(itemCounts)
      .filter((key) => key !== "undefined")
      .reduce((obj, key) => {
        obj[key] = itemCounts[key];
        return obj;
      }, {});

    return Object.keys(filterList)?.reduce(
      (a, b) => (filterList[a] > filterList[b] ? a : b),
      ""
    );
  };

  const getOrdersCountGraph = async (
    list,
    timeLine,
    startDate = null,
    endDate = null
  ) => {
    let graphDataList = [];

    const orderCountByDate = list?.map((x) =>
      moment(x?.createdAt).format("DD-MM-YYYY")
    );

    const totalOrderCountByDate = orderCountByDate?.reduce(
      (acc, o) => ((acc[o] = (acc[o] || 0) + 1), acc),
      {}
    );

    if (timeLine === "week") {
      const weekStart = moment().startOf("week").format("DD-MM-YYYY");
      graphDataList = await getOrdersCountGraphByTimeLine(
        totalOrderCountByDate,
        weekStart,
        "ddd",
        7
      );
    } else if (timeLine === "month") {
      const monthStart = moment().startOf("month").format("DD-MM-YYYY");
      graphDataList = await getOrdersCountGraphByTimeLine(
        totalOrderCountByDate,
        monthStart,
        "DD-MM-YYYY",
        moment().daysInMonth()
      );
    } else if (timeLine === "year") {
      graphDataList = await getOrdersCountGraphByYear(list);
    } else {
      graphDataList = await getOrdersCountGraphByCustom(
        totalOrderCountByDate,
        startDate,
        endDate
      );
    }

    return graphDataList;
  };

  const getOrdersCountGraphByTimeLine = (data, start, format, noOfDays) => {
    let countList = [];
    const isSameFormat = format === "DD-MM-YYYY";
    for (let i = 0; i < noOfDays; i++) {
      const date = moment(start, "DD-MM-YYYY")
        .add(i, "days")
        .format("DD-MM-YYYY");
      countList.push({
        date: isSameFormat ? date : moment(date, "DD-MM-YYYY").format(format),
        count: data?.[date] ?? 0,
      });
    }
    return countList;
  };

  const getOrdersCountGraphByYear = (list) => {
    const monthList = moment.monthsShort();
    const orderCount = list?.map((x) => moment(x?.createdAt).format("MMM"));
    const orderCountData = orderCount?.reduce(
      (acc, o) => ((acc[o] = (acc[o] || 0) + 1), acc),
      {}
    );
    return monthList.map((x) => {
      return {
        date: x,
        count: orderCountData?.[x] ?? 0,
      };
    });
  };

  const getOrdersCountGraphByCustom = async (
    data,
    startDate = null,
    endDate = null
  ) => {
    let dateCountList = [];
    if (Object.keys(data).length) {
      const sortList = Object.keys(data).sort((a, b) =>
        moment(a, "DD-MM-YYYY").diff(moment(b, "DD-MM-YYYY"))
      );

      let start = moment(startDate, "YYYY-MM-DD");
      let end = moment(endDate, "YYYY-MM-DD");

      if (sortList.length) {
        if (startDate === null) {
          start = moment(sortList[0], "DD-MM-YYYY").format("YYYY-MM-DD");
          start = moment(start, "YYYY-MM-DD");
        }
        if (endDate === null) {
          end = moment(sortList[sortList.length - 1], "DD-MM-YYYY").format(
            "YYYY-MM-DD"
          );
          end = moment(end, "YYYY-MM-DD");
        }
      }

      const days = end.diff(start, "days");
      dateCountList = await getOrdersCountGraphByTimeLine(
        data,
        start,
        "DD-MM-YYYY",
        days + 1
      );
    }

    return dateCountList;
  };

  const onResetClick = () => {
    setFromDate(null);
    setToDate(null);
    setError(null);
    getViewItemsData(null, null);
    getOrderData(null, null);
  };

  const handleDateFilter = (val) => {
    setDateFilter(val);

    let fromDate = null;
    let toDate = null;
    if (val === "week") {
      fromDate = moment().startOf("week").format("DD MMM YYYY");
      toDate = moment().endOf("week").format("DD MMM YYYY");
    } else if (val === "month") {
      fromDate = moment().startOf("month").format("DD MMM YYYY");
      toDate = moment().endOf("month").format("DD MMM YYYY");
    } else if (val === "year") {
      fromDate = moment().startOf("year").format("DD MMM YYYY");
      toDate = moment().endOf("year").format("DD MMM YYYY");
    }

    setFromDate(fromDate);
    setToDate(toDate);
  };

  const CardData = ({ title, value }) => {
    return (
      <Card
        sx={{
          minHeight: 110,
          backgroundColor: theme_mode?.bg8,
          color: theme_mode?.cardText,
        }}
      >
        <CardContent>
          <Typography
            sx={{ fontFamily: "InterSemiBold" }}
            gutterBottom
            variant="h5"
            component="div"
          >
            {title}
          </Typography>
          <Typography variant="body1" sx={{ fontFamily: "InterSemiBold" }}>
            {value}
          </Typography>
        </CardContent>
      </Card>
    );
  };

  return (
    <Container page={"settings"} p={1.5} fpx={2} fpy={0.5}>
      {loading ? (
        <Loader />
      ) : (
        <Grid px={2} mb={2}>
          <Grid mb={2} container item xs={12}>
            <Grid item xs={4}>
              <Box
                onClick={() => navigate("/analytics")}
                sx={{ cursor: "pointer", width: "fit-content" }}
              >
                <BackBtnIcon width={33} height={33} />
              </Box>
            </Grid>
            <Grid
              item
              xs={8}
              display={"flex"}
              justifyContent={"flex-end"}
              alignItems="center"
            >
              {dateFilter === "custom" ? (
                <FromToDatePicker
                  themeMode={props.themeMode}
                  fromDate={fromDate}
                  toDate={toDate}
                  setFromDate={setFromDate}
                  setToDate={setToDate}
                  error={error}
                  showReset={true}
                  showIcon={true}
                  onReset={onResetClick}
                />
              ) : null}
              <SettingSelectField
                page={"viewItems"}
                value={dateFilter}
                setValue={(val) => handleDateFilter(val)}
                editable={true}
                placeholder={""}
                required={true}
                variant={"filled"}
                menuList={DropdownList}
                error={error?.country}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} mb={2} spacing={3}>
            <Grid item xs={12} md={4} mb={1}>
              <CardData
                title="Average Spend"
                value={`${
                  CURRENCY_SYMBOL[props.restaurantDetail?.currecnyIcon]
                }${orderAverageSpend}`}
              />
            </Grid>
            <Grid item xs={12} md={4} mb={1}>
              <CardData title="Best Seller" value={orderBestSeller} />
            </Grid>
            <Grid item xs={12} md={4} mb={1}>
              <CardData
                title="Delivery vs Dine In"
                value={deliverDineInRatio}
              />
            </Grid>
          </Grid>
          <Grid>
            <ViewedItemsTableList
              themeMode={props.themeMode}
              itemList={viewedItemList}
            />
            {viewedItemList.length ? null : (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  minHeight: 300,
                }}
              >
                <Typography
                  py={8}
                  sx={{
                    color: "#FFF",
                    fontSize: 12,
                    fontFamily: "InterSemiBold",
                    textAlign: "center",
                  }}
                >
                  No Viewed Items
                </Typography>
              </Box>
            )}
          </Grid>
          {orderDateCount.length ? (
            <Grid item xs={12} mt={5}>
              <Card
                sx={{
                  backgroundColor: theme_mode?.bg8,
                  pb: 3,
                }}
              >
                <OrderCountGraph dataset={orderDateCount} />
              </Card>
            </Grid>
          ) : null}
        </Grid>
      )}
      <AlertMsg
        msgAlert={msgAlert}
        onCloseAlertMsg={() =>
          setMsgAlert({ open: false, message: "", msgType: "error" })
        }
      />
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    restaurantId: state.userData.restaurantId,
    themeMode: state.userData.themeMode,
    restaurantMenuList: state.userData.restaurantMenuList,
    restaurantDetail: state.userData.restaurantDetail,
  };
};

export default connect(mapStateToProps, null)(ViewItems);
