import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";

import { connect } from "react-redux";
import { updateRestaurantDetail } from "../../redux/actions/userDataActions";

import * as Api from "../../api";

import Container from "../../components/Container";
import Loader from "../../components/Loader";
import MenuStyleModal from "../../components/MenuStyleModal";
import SubscriptionFeatureList from "../../components/SubscriptionFeatureList";
import AlertMsg from "../../components/AlertMsg";
import ConfirmAlert from "../../components/ConfirmAlert";

import { CONTAINER_HEIGHT, SUBSCRIPTION_IDS } from "../../constants";
import { THEME_MODE } from "../../constants/Theme";

import { ReactComponent as SubscriptionIcon } from "../../assets/images/subscription.svg";

let SubscriptionData = {};

SubscriptionData[`${SUBSCRIPTION_IDS["starter"]}`] = {
  bgImg: require("../../assets/images/plan_starter.png"),
};

SubscriptionData[`${SUBSCRIPTION_IDS["plus"]}`] = {
  bgImg: require("../../assets/images/plan_plus.png"),
};

SubscriptionData[`${SUBSCRIPTION_IDS["premium"]}`] = {
  bgImg: require("../../assets/images/plan_premium.png"),
};

// let MenuDisplayList = [require('../../assets/images/menu_style1.png'), require('../../assets/images/menu_style2.png')];

const Subscriptions = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];
  const styles = styles1(theme_mode);

  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [currentPlan, setCurrentPlan] = useState("");
  const [subscriptionList, setSubscriptionList] = useState([]);
  const [menuStyle, setMenuStyle] = useState(0);
  const [menuDisplayList, setMenuDisplayList] = useState([]);
  const [menuStyleModalVisible, setMenuStyleModalVisible] = useState(false);
  const [confirmUpgradePlanModal, setConfirmUpgradePlanModal] = useState(null);
  const [msgAlert, setMsgAlert] = useState({
    open: false,
    message: "",
    msgType: "error",
  });

  useEffect(() => {
    getSubscriptionsList();
  }, []);

  const getSubscriptionsList = () => {
    setLoading(true);
    Api.getSubscriptions().then((response) => {
      if (response.success) {
        let data = response.data;
        if (data.rows.length) {
          getSubscriptionFeaturesList(data.rows);
        } else {
          setLoading(false);
        }
      } else {
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
        setLoading(false);
      }
    });
  };

  const getSubscriptionFeaturesList = (subs) => {
    setLoading(true);
    Api.getSubscriptionFeatures().then((response) => {
      if (response.success) {
        let data = response.data;
        if (data.rows.length) {
          let list = [];
          subs.map((x, i) => {
            let subData = {
              ...x,
              features: data.rows.filter((y) => y._idSubscriptions == x._id),
            };
            let index = subData.features.findIndex(
              (y) =>
                y?.addOnFeatures?.length &&
                y?.addOnFeatures?.[0]?.styleName != ""
            );
            if (index >= 0) {
              subData.features[index] = {
                ...subData.features[index],
                active: true,
              };
            }
            list.push(subData);
          });
          setSubscriptionList(list);
        } else {
          setSubscriptionList(subs);
        }
        setCurrentPlan(
          props?.restaurantDetail?.accountDetails?._idSubscriptions ?? ""
        );
      } else {
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
      setLoading(false);
    });
  };

  const onActiveFeature = (data, active) => {
    // let list = [...planFeatureList];
    // let index = list.findIndex((x) => x._id == x._id);
    // if (index >= 0) {
    //   list[index]["active"] = active;
    //   setPlanFeatureList(list);
    // }
  };

  const onConfirmUpgradePlan = () => {
    const plan = confirmUpgradePlanModal;
    if (plan) {
      const _idSubscriptions = plan?._id;

      const data = {
        accountDetails: {
          ...props?.restaurantDetail?.accountDetails,
          _idSubscriptions,
          subscriptionsName: plan?.name ?? "",
        },
      };
      const datas = JSON.stringify(data);

      setLoading(true);
      Api.updateRestaurant(datas, props.restaurantId).then((response) => {
        if (response.success) {
          props.updateRestaurantDetail({
            ...props.restaurantDetail,
            ...data,
          });
          setCurrentPlan(_idSubscriptions);
          setConfirmUpgradePlanModal(null);
          setMsgAlert({
            open: true,
            message: response.msg,
            msgType: "success",
          });
        } else {
          setMsgAlert({ open: true, message: response.msg, msgType: "error" });
        }
        setLoading(false);
      });
    } else {
      setConfirmUpgradePlanModal(null);
    }
  };

  const onToggleMenuStyleModalVisible = (val) => {
    setMenuStyleModalVisible(val);
  };

  const openMenuStyleModalVisible = (item) => {
    setMenuDisplayList(item?.addOnFeatures);
    setMenuStyleModalVisible(true);
  };

  const onUpgradePlan = (item) => {
    setConfirmUpgradePlanModal(item);
  };

  return (
    <Container
      page={"settings"}
      navTitle={"Subscriptions"}
      p={"0"}
      fpx={3}
      fpy={3}
      minHeight={CONTAINER_HEIGHT - 90}
      bgColor={theme_mode.card}
    >
      {loading ? (
        <Loader />
      ) : (
        <Grid container item xs={12}>
          {subscriptionList.map((plan, index) => {
            const features = plan?.features ?? [];
            const activePlan = plan?.["_id"] === currentPlan;
            return (
              <Grid container item xs={4} justifyContent={"center"}>
                {props.themeMode === "light" &&
                plan._id === SUBSCRIPTION_IDS["starter"] ? (
                  <Box sx={styles.planLeftBg}>
                    <img
                      src={require("../../assets/images/plan_starter_bg.png")}
                      style={styles.planLeftBgImg}
                    />
                  </Box>
                ) : null}

                {props.themeMode === "light" &&
                SubscriptionData?.[plan._id]?.bgImg ? (
                  <Box sx={styles.planBg}>
                    <img
                      src={SubscriptionData?.[plan._id]?.bgImg}
                      style={styles.planBgImg}
                    />
                  </Box>
                ) : null}

                <Grid
                  mt={2}
                  item
                  xs={11}
                  p={2}
                  style={{
                    zIndex: 3,
                    backgroundColor:
                      props.themeMode === "light"
                        ? "transparent"
                        : theme_mode.card2,
                    borderRadius: 8,
                  }}
                >
                  {activePlan ? (
                    <Box sx={styles.activePlanView}>
                      <Box sx={styles.activePlan}>ACTIVE PLAN</Box>
                    </Box>
                  ) : null}
                  <Typography mt={2} sx={styles.planTitle}>
                    {plan?.["name"]}
                  </Typography>
                  <Box sx={styles.planPriceView}>
                    <Typography sx={styles.planPrice}>
                      {plan?.["currency"]}
                      {plan?.["price"]?.["value"]}
                    </Typography>
                    <Typography sx={styles.planDuration}>
                      {plan?.["duration"]}
                    </Typography>
                  </Box>
                  {features.length ? (
                    <>
                      <Box mt={3}>
                        <Typography sx={styles.featureTitleText}>
                          Features:
                        </Typography>
                        <Box>
                          {features.map((feature, i) => {
                            return (
                              <SubscriptionFeatureList
                                key={i}
                                item={feature}
                                themeMode={props.themeMode}
                                menuStyle={
                                  menuDisplayList.length
                                    ? menuDisplayList[menuStyle]?.imageURL
                                    : ""
                                }
                                onActiveFeature={onActiveFeature}
                                openMenuStyleModalVisible={
                                  openMenuStyleModalVisible
                                }
                              />
                            );
                          })}
                        </Box>
                      </Box>
                      <Box sx={styles.descView}>
                        <Typography sx={styles.descText}>
                          Tap on the features to{" "}
                        </Typography>
                        <Typography mx={0.2} sx={styles.descUpdateText}>
                          {" "}
                          Update/Unlock{" "}
                        </Typography>
                        <Typography sx={styles.descText}>the plan</Typography>
                      </Box>
                    </>
                  ) : null}
                  {activePlan ? null : (
                    <Box>
                      <Button
                        type="button"
                        sx={styles.upgradePlanBtn}
                        onClick={() => onUpgradePlan(plan)}
                      >
                        Upgrade Plan
                      </Button>
                    </Box>
                  )}
                  {/*<Box sx={styles.cancelSaveBtn}>*/}
                  {/*  <Button*/}
                  {/*    type='button'*/}
                  {/*    sx={styles.saveBtn}*/}
                  {/*    onClick={() => null}>*/}
                  {/*    Save*/}
                  {/*  </Button>*/}
                  {/*  <Button*/}
                  {/*    type='button'*/}
                  {/*    sx={styles.cancelBtn}*/}
                  {/*    onClick={() => null}>*/}
                  {/*    Cancel*/}
                  {/*  </Button>*/}
                  {/*</Box>*/}
                </Grid>

                <MenuStyleModal
                  themeMode={props.themeMode}
                  modelVisible={menuStyleModalVisible}
                  menuStyle={menuStyle}
                  menuDisplayList={menuDisplayList}
                  onSubmit={setMenuStyle}
                  onToggle={onToggleMenuStyleModalVisible}
                />
              </Grid>
            );
          })}
        </Grid>
      )}
      <AlertMsg
        msgAlert={msgAlert}
        onCloseAlertMsg={() =>
          setMsgAlert({ open: false, message: "", msgType: "error" })
        }
      />
      <ConfirmAlert
        confirmDelete={confirmUpgradePlanModal !== null}
        title={"Are you sure you want to upgrade the plan?"}
        titleStyle={{ fontFamily: "InterMedium !important" }}
        firstBtn={"No"}
        secondBtn={loading ? "Please Wait" : "Yes"}
        btnLoading={loading}
        icon={<SubscriptionIcon />}
        onCloseConfirmDelete={() => onUpgradePlan(null)}
        onConfirmDelete={onConfirmUpgradePlan}
        themeMode={props.themeMode}
      />
    </Container>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateRestaurantDetail: (data) => dispatch(updateRestaurantDetail(data)),
  };
};

const mapStateToProps = (state) => {
  return {
    restaurantId: state.userData.restaurantId,
    restaurantDetail: state.userData.restaurantDetail,
    themeMode: state.userData.themeMode,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Subscriptions);

const styles1 = (Theme) => ({
  planBg: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    zIndex: 2,
  },
  planBgImg: {
    height: "100%",
    width: "100%",
  },
  planLeftBg: {
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 3,
  },
  planLeftBgImg: {
    height: "100%",
    width: window.innerWidth / 3,
  },
  activePlan: {
    backgroundColor: Theme.activePlanBg,
    cursor: "pointer",
    textAlign: "center",
    fontFamily: "InterSemiBold",
    color: Theme.lightText,
    fontSize: 10,
    borderRadius: "30px",
    width: 100,
    py: 0.8,
  },
  activePlanView: {
    minHeight: 30,
    mt: 2,
  },
  planTitle: {
    color: Theme.text2,
    fontSize: 30,
    fontFamily: "InterBold",
  },
  planPriceView: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  planPrice: {
    color: Theme.text8,
    fontSize: 15,
    fontFamily: "InterSemiBold",
  },
  planDuration: {
    color: Theme.text8,
    fontSize: 10,
    fontFamily: "InterMedium",
    ml: 1,
  },
  featureTitleText: {
    color: Theme.text5,
    fontSize: 15,
    fontFamily: "InterBold",
  },
  descView: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(255, 204, 0, 0.3)",
    borderRadius: "5px",
    px: 2,
    py: 0.8,
    mt: 2,
  },
  descText: {
    color: Theme.text5,
    fontSize: 12,
    fontFamily: "InterMedium",
  },
  descUpdateText: {
    color: Theme.text5,
    fontSize: 12,
    fontFamily: "InterSemiBold",
  },
  upgradePlanBtn: {
    backgroundColor: Theme.greenBg,
    cursor: "pointer",
    textTransform: "capitalize",
    color: Theme.lightText,
    fontFamily: "InterSemiBold",
    fontSize: 14,
    borderRadius: "9px",
    height: 40,
    width: "100%",
    mt: 2,
    "&:hover": {
      backgroundColor: Theme.greenBg + " !important",
    },
  },
  cancelSaveBtn: {
    display: "flex",
    flexDirection: "row",
  },
  saveBtn: {
    backgroundColor: Theme.greenBg,
    cursor: "pointer",
    textTransform: "capitalize",
    color: Theme.lightText,
    fontFamily: "InterSemiBold",
    fontSize: 14,
    borderRadius: "9px",
    height: 40,
    width: "100%",
    mr: 0.5,
    "&:hover": {
      backgroundColor: Theme.greenBg + " !important",
    },
  },
  cancelBtn: {
    backgroundColor: Theme.redBg,
    cursor: "pointer",
    textTransform: "capitalize",
    color: Theme.lightText,
    fontFamily: "InterSemiBold",
    fontSize: 14,
    borderRadius: "9px",
    height: 40,
    width: "100%",
    ml: 0.5,
    "&:hover": {
      backgroundColor: Theme.redBg + " !important",
    },
  },
});
