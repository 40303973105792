import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";

import SettingTextField from "./SettingTextField";

import useDebounce from "./UseDebounce";

import { ReactComponent as InfoIcon } from "../assets/images/rest_info.svg";

const PortalSettingsColorInput = (props) => {
  const { label, value, field, error = "", info = "" } = props;

  const [colorVal, setColorVal] = useState(value);

  const debouncedValue = useDebounce(colorVal, 500);

  useEffect(() => {
    props.handlerOnColorChange(field, colorVal);
  }, [debouncedValue]);

  const handlerOnColorChange = (val) => {
    setColorVal(val);
  };

  return (
    <>
      <Box mb={1} display={"flex"} alignItems={"center"}>
        <Typography
          sx={{
            color: "#FFFFFF",
            fontSize: 14.5,
            fontFamily: "InterSemiBold !important",
          }}
        >
          {label}
        </Typography>
        {info ? (
          <Tooltip
            componentsProps={{
              tooltip: {
                sx: {
                  marginTop: "0px !important",
                  marginBottom: "0px !important",
                  backgroundColor: "#EEE",
                  color: "#000",
                },
              },
            }}
            title={info}
            placement="top"
          >
            <InfoIcon
              height={15}
              width={15}
              style={{ cursor: "pointer", marginLeft: 4 }}
            />
          </Tooltip>
        ) : null}
      </Box>
      <Box>
        <SettingTextField
          page={"customRestaurantInfo"}
          editable={true}
          value={colorVal}
          setValue={handlerOnColorChange}
          placeholder={""}
          required={true}
          variant={"filled"}
          error={error}
          leftView={
            <Box
              sx={{
                width: 18,
                height: 18,
                border: "0.5px solid #AAA",
                backgroundColor: colorVal,
              }}
            />
          }
        />
      </Box>
    </>
  );
};

export default PortalSettingsColorInput;
