import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import * as Api from "../../api";

import { connect } from "react-redux";

import Container from "../../components/Container";
import Loader from "../../components/Loader";
import AlertMsg from "../../components/AlertMsg";
import TimerRegisterTableList from "../../components/TimerRegisterTableList";
import SettingSelectField from "../../components/SettingSelectField";
import FromToDatePicker from "../../components/FromToDatePicker";

import { ReactComponent as BackBtnIcon } from "../../assets/images/back_btn.svg";

import { THEME_MODE } from "../../constants/Theme";
import { CONTAINER_HEIGHT } from "../../constants";

function getTimeDiff(start, end) {
  return moment.duration(moment(end, "HH:mm").diff(moment(start, "HH:mm")));
}

const dateFormat = "DD MMM YYYY";

const TimerRegister = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [timerRegList, setTimerRegList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [fromDate, setFromDate] = useState(moment().format(dateFormat));
  const [toDate, setToDate] = useState(moment().format(dateFormat));
  const [msgAlert, setMsgAlert] = useState({
    open: false,
    message: "",
    msgType: "error",
  });

  useEffect(() => {
    getUserList();
  }, []);

  useEffect(() => {
    if (userList.length && selectedUser) {
      getRestaurantUserCheckIn();
    }
  }, [selectedUser, fromDate, toDate]);

  const getUserList = async () => {
    setLoading(true);
    const response = await Api.getUserRolesList(props.restaurantId, null);
    if (response.success) {
      const list = response.data.rows
        .filter((x) => x?.userDetails?.length)
        .map((user) => {
          return {
            label: user?.userDetails?.[0]?.name ?? "",
            value: user?._idUser,
          };
        });
      setUserList(list);
      setSelectedUser(list?.[0]?.value ?? "");
    } else {
      setMsgAlert({ open: true, message: response.msg, msgType: "error" });
    }
    setLoading(false);
  };

  const getRestaurantUserCheckIn = async () => {
    setLoading(true);
    let filter = `?pageSize=9999&pageNum=1&filter__idUser=${selectedUser}&orderBy=createdAt&orderByDir=desc`;

    if (fromDate || toDate) {
      let start = "";
      let end = "";
      if (fromDate && toDate) {
        start = moment(fromDate, dateFormat).format("x");
        end = moment(toDate, dateFormat).add(1, "day").format("x");
      } else {
        if (fromDate) {
          start = moment(fromDate, dateFormat).format("x");
          end = moment(fromDate, dateFormat).add(1, "day").format("x");
        }
        if (toDate) {
          start = moment(toDate, dateFormat).format("x");
          end = moment(toDate, dateFormat).add(1, "day").format("x");
        }
      }
      filter += `&filter_checkInDate=${"btw-" + [start, end]}`;
    }

    const response = await Api.getRestaurantUserCheckIn(
      props.restaurantId,
      filter
    );
    if (response.success) {
      const uniqueDate = Array.from(
        new Set(
          response.data.rows.map((row) =>
            moment(row.checkInDate).format("DD-MM-YYYY")
          )
        )
      );

      const list = uniqueDate.map((date) => {
        const clockInTimeList = response.data.rows
          .filter(
            (row) =>
              moment(row.checkInDate).format("DD-MM-YYYY") === date &&
              row?.clockInTime
          )
          .map((d) => moment(d?.clockInTime, "HH:mm"));
        const clockOutTimeList = response.data.rows
          .filter(
            (row) =>
              moment(row.checkInDate).format("DD-MM-YYYY") === date &&
              row?.clockOutTime
          )
          .map((d) => moment(d?.clockOutTime, "HH:mm"));

        const clockInTime = clockInTimeList?.length
          ? moment.min(clockInTimeList).format("HH:mm")
          : "";
        const clockOutTime = clockOutTimeList?.length
          ? moment.max(clockOutTimeList).format("HH:mm")
          : "";

        let totalHours = "0";
        if (clockInTime && clockOutTime) {
          const diff = getTimeDiff(clockInTime, clockOutTime);
          const hrs = diff.hours();
          const mins = diff.minutes();
          totalHours = "";
          if (hrs > 0) {
            totalHours = `${hrs} ${hrs > 1 ? "hrs" : "hr"} `;
          }
          totalHours += mins > 0 ? ` ${mins} ${mins > 1 ? "mins" : "min"}` : 0;
        }

        return {
          date,
          name: userList?.find((x) => x?.value === selectedUser)?.label ?? "",
          clockInTime,
          clockOutTime,
          totalHours,
        };
      });
      setTimerRegList(list);
    } else {
      setMsgAlert({ open: true, message: response.msg, msgType: "error" });
    }
    setLoading(false);
  };

  const handleUserSelect = (val) => {
    setSelectedUser(val);
  };

  const handleDateSelect = (type, val) => {
    console.log("valvalvalval", val);
    if (type === "from") {
      setFromDate(val);
    } else {
      setToDate(val);
    }
  };

  return (
    <Container page={"settings"} p={1.5} minHeight={CONTAINER_HEIGHT - 60}>
      {loading ? (
        <Loader />
      ) : (
        <Grid
          p={1.5}
          sx={{ backgroundColor: theme_mode.card3, borderRadius: "15px" }}
        >
          <Grid mb={2} container item xs={12}>
            <Grid item xs={5}>
              <Box
                onClick={() => navigate("/settings")}
                sx={{ cursor: "pointer", width: "fit-content" }}
              >
                <BackBtnIcon width={33} height={33} />
              </Box>
            </Grid>
            <Grid container item xs={7} justifyContent={"flex-end"}>
              <Box mb={1}>
                <FromToDatePicker
                  themeMode={props.themeMode}
                  fromDate={fromDate}
                  toDate={toDate}
                  setFromDate={(val) => handleDateSelect("from", val)}
                  setToDate={(val) => handleDateSelect("to", val)}
                  error={""}
                  showReset={false}
                  showIcon={true}
                  backgroundColor={theme_mode.inputBg}
                  onReset={() => null}
                />
              </Box>
              <SettingSelectField
                page={"viewItems"}
                value={selectedUser}
                setValue={(val) => handleUserSelect(val)}
                editable={true}
                placeholder={""}
                required={true}
                variant={"filled"}
                menuList={userList}
                textInputViewStyle={{ borderRadius: 2 }}
                error={""}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TimerRegisterTableList
              themeMode={props.themeMode}
              itemList={timerRegList}
            />
          </Grid>
        </Grid>
      )}
      <AlertMsg
        msgAlert={msgAlert}
        onCloseAlertMsg={() =>
          setMsgAlert({ open: false, message: "", msgType: "error" })
        }
      />
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    restaurantId: state.userData.restaurantId,
    themeMode: state.userData.themeMode,
  };
};

export default connect(mapStateToProps, null)(TimerRegister);
