import React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";

import { THEME_MODE } from "../constants/Theme";

let tableHeaderList = ["Name", "Date", "Clock In", "Clock Out", "Total Hours"];

const TimerRegisterTableList = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      color: theme_mode.tableHeaderText2,
      fontFamily: "InterMedium",
      fontSize: 14,
      border: 0,
    },
    [`&.${tableCellClasses.body}`]: {
      borderBottom: `${props.themeMode == "dark" ? 5 : 1}px solid ${
        theme_mode.tableBorder
      }`,
      color: theme_mode.tableRowText,
      fontFamily: "InterMedium",
      fontSize: 13,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme_mode.tableRow2,
    },
    "&:nth-of-type(even)": {
      backgroundColor: theme_mode.tableRow,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const { itemList } = props;

  return (
    <TableContainer
      component={Paper}
      sx={{
        borderRadius: 0,
        "&.MuiTableContainer-root": { backgroundColor: theme_mode.table },
      }}
      elevation={0}
    >
      {itemList.length ? (
        <Table aria-label="customized table">
          <TableHead sx={{ backgroundColor: theme_mode.tableHeader2 }}>
            <TableRow>
              {tableHeaderList.map((x, i) => {
                return (
                  <StyledTableCell key={i} sx={{ borderBottom: "none" }}>
                    <Box>{x}</Box>
                  </StyledTableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {itemList.map((item, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell>{item.name}</StyledTableCell>
                <StyledTableCell>{item?.date}</StyledTableCell>
                <StyledTableCell>
                  {item.clockInTime ? item.clockInTime : "-"}
                </StyledTableCell>
                <StyledTableCell>
                  {item.clockOutTime ? item.clockOutTime : "-"}
                </StyledTableCell>
                <StyledTableCell>{item.totalHours}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      ) : null}
    </TableContainer>
  );
};

export default TimerRegisterTableList;
