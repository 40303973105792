import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import SettingTextField from "./SettingTextField";
import SwitchInput from "../containers/Settings/SwitchInput";

import { ReactComponent as EditIcon } from "../assets/images/edit2.svg";
import { ReactComponent as DeleteIcon } from "../assets/images/delete2.svg";
import { ReactComponent as PlusIcon } from "../assets/images/plus2.svg";

import { THEME_MODE } from "../constants/Theme";

const AddListFloor = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];

  const [floorName, setFloorName] = useState("");
  const [fromVal, setFromVal] = useState("");
  const [toVal, setToVal] = useState("");
  const [isTakeaway, setIsTakeaway] = useState(false);
  const [error, setError] = useState(null);

  let { item } = props;

  useEffect(() => {
    if (item?.type == "edit") {
      setFloorName(item.name);
      setFromVal(item?.fromNumber);
      setToVal(item?.toNumber);
      setIsTakeaway(item?.isTakeaway ?? false);
    }
  }, [item]);

  const onSaveClick = () => {
    let err = null;

    if (floorName.trim() == "") {
      err = { ...err, floor: "Floor name is required" };
    }

    if (fromVal == "") {
      err = { ...err, from: "From is required" };
    } else {
      if (/[^0-9]/g.test(fromVal)) {
        err = { ...err, from: "Invalid Value" };
      }
    }

    if (toVal == "") {
      err = { ...err, to: "To is required" };
    } else {
      if (/[^0-9]/g.test(toVal)) {
        err = { ...err, to: "Invalid Value" };
      } else {
        if (parseInt(fromVal) >= parseInt(toVal)) {
          err = { ...err, to: "To should be greater than from" };
        }
      }
    }

    setError(err);
    if (err != null) {
      return;
    }

    let data = {
      ...item,
      name: floorName,
      from: fromVal,
      to: toVal,
      isTakeaway: isTakeaway,
    };
    props.onSave(data);
  };

  const onEditClick = () => {
    props.onEdit(item);
  };

  const onDeleteClick = () => {
    props.onDelete(item);
  };

  const handlerIsTakeaway = () => {
    setIsTakeaway(!isTakeaway);
  };

  const onCancelClick = () => {
    props.onCancel(item);
  };

  if (item?.type == "add" || item?.type == "edit") {
    return (
      <Box
        className={"main-view"}
        mb={2}
        style={{
          backgroundColor: theme_mode.bg8,
          border: `1px solid` + theme_mode.border2,
        }}
      >
        <Box px={2} className={"floor-name-input"} mt={error?.floor ? 1.5 : 0}>
          <Box className={"floor-name-takeaway-view"}>
            <SettingTextField
              page={"addFloor"}
              value={floorName}
              setValue={setFloorName}
              backgroundColor={theme_mode.inputBg}
              border={"1px solid " + theme_mode.border3}
              height={"35px"}
              borderRadius={"8px"}
              marginTop={0.2}
              label={""}
              placeholder={"Enter floor name"}
              required={true}
              variant={"filled"}
              error={error?.floor}
            />

            <Box ml={1}>
              <Typography className="floor-takeaway-label">
                Take away
              </Typography>
              <SwitchInput checked={isTakeaway} onChange={handlerIsTakeaway} />
            </Box>
          </Box>
        </Box>

        <Box px={2} pb={2} pt={1}>
          <Typography
            className={"table-num-text"}
            mb={1}
            style={{ color: theme_mode.cardText }}
          >
            Table Numbers
          </Typography>
          <Box className={"from-to-main-view"}>
            <Box className={"from-to-view"}>
              <Typography
                className={"from-to-text"}
                mr={1}
                style={{ color: theme_mode.cardText }}
              >
                From
              </Typography>
              <Box>
                <SettingTextField
                  page={"addFloor"}
                  value={fromVal}
                  setValue={(val) => setFromVal(val.replace(/[^0-9]/g, ""))}
                  backgroundColor={theme_mode.inputBg}
                  border={"1px solid " + theme_mode.border3}
                  height={"40px"}
                  width={"40px"}
                  borderRadius={"8px"}
                  textAlign={"center"}
                  px={"0 !important"}
                  marginTop={0.5}
                  label={""}
                  placeholder={""}
                  required={true}
                  variant={"filled"}
                  error={error?.from}
                />
              </Box>
            </Box>
            <Box className={"from-to-view"}>
              <Typography
                className={"from-to-text"}
                mr={1}
                style={{ color: theme_mode.cardText }}
              >
                To
              </Typography>
              <Box>
                <SettingTextField
                  page={"addFloor"}
                  value={toVal}
                  setValue={(val) => setToVal(val.replace(/[^0-9]/g, ""))}
                  backgroundColor={theme_mode.inputBg}
                  border={"1px solid " + theme_mode.border3}
                  height={"40px"}
                  width={"40px"}
                  borderRadius={"8px"}
                  textAlign={"center"}
                  px={"0 !important"}
                  marginTop={0.5}
                  label={""}
                  placeholder={""}
                  required={true}
                  variant={"filled"}
                  error={error?.to}
                />
              </Box>
            </Box>
          </Box>
          <Box className={"btn-main-view"}>
            <Box
              className={"btn-view"}
              mr={0.5}
              style={{ backgroundColor: theme_mode.black }}
              onClick={onSaveClick}
            >
              <Typography
                className={"btn-text"}
                mr={1}
                style={{ color: theme_mode.btnText }}
              >
                Save Floor
              </Typography>
              <PlusIcon width={16} height={16} />
            </Box>

            <Box
              className={"btn-view"}
              sx={{ backgroundColor: theme_mode.black, ml: 0.5 }}
              onClick={onCancelClick}
            >
              <Typography
                className={"btn-text"}
                style={{ color: theme_mode.btnText }}
              >
                Cancel
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <Box
      className={"main-view"}
      mb={2}
      style={{
        backgroundColor: theme_mode.bg8,
        border: "1px solid" + theme_mode.border2,
      }}
    >
      <Box
        className={"floor-name-view"}
        style={{ backgroundColor: theme_mode.headerBg }}
        px={2}
      >
        <Typography
          className={"floor-name-text"}
          style={{ color: theme_mode.lightText }}
        >
          {item.name}
        </Typography>
        <Box>
          <Typography className="floor-takeaway-label">Take away</Typography>
          <Typography
            className="floor-takeaway-value"
            sx={{color: item?.isTakeaway ? theme_mode.greenBg : theme_mode.redBg}}
          >
            {item?.isTakeaway ? "ON" : "OFF"}
          </Typography>
        </Box>
      </Box>
      <Box px={2} pb={2} pt={1}>
        <Typography
          className={"table-num-text"}
          mb={1}
          style={{ color: theme_mode.cardText }}
        >
          Table Numbers
        </Typography>
        <Box className={"from-to-main-view"}>
          <Box className={"from-to-view"}>
            <Typography
              className={"from-to-text"}
              mr={1}
              style={{ color: theme_mode.cardText }}
            >
              From
            </Typography>
            <Box
              className={"from-to-val-view"}
              style={{
                backgroundColor:
                  props.themeMode == "light"
                    ? theme_mode.btnBg
                    : theme_mode.headerBg,
              }}
            >
              <Typography
                className={"from-to-val-text"}
                style={{ color: theme_mode.btnText }}
              >
                {item?.fromNumber}
              </Typography>
            </Box>
          </Box>
          <Box className={"from-to-view"}>
            <Typography
              className={"from-to-text"}
              mr={1}
              style={{ color: theme_mode.cardText }}
            >
              To
            </Typography>
            <Box
              className={"from-to-val-view"}
              style={{
                backgroundColor:
                  props.themeMode == "light"
                    ? theme_mode.btnBg
                    : theme_mode.headerBg,
              }}
            >
              <Typography
                className={"from-to-val-text"}
                style={{ color: theme_mode.btnText }}
              >
                {item?.toNumber}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box className={"btn-main-view"}>
          <Box
            className={"btn-view"}
            mr={0.5}
            style={{ backgroundColor: theme_mode.black }}
            onClick={item?.toNumber ? onEditClick : () => null}
          >
            <Typography
              className={"btn-text"}
              mr={1}
              style={{
                color: theme_mode.btnText,
                opacity: item?.toNumber ? 1 : 0.5,
                cursor: item?.toNumber ? "pointer" : "default",
              }}
            >
              Edit Tables
            </Typography>
            <EditIcon width={16} height={16} />
          </Box>
          <Box
            className={"btn-view"}
            ml={0.5}
            style={{ backgroundColor: theme_mode.black }}
            onClick={onDeleteClick}
          >
            <Typography
              className={"btn-text"}
              mr={1}
              style={{ color: theme_mode.btnText }}
            >
              Delete Floor
            </Typography>
            <DeleteIcon width={16} height={16} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AddListFloor;
