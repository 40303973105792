import React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Switch from "@mui/material/Switch";

import styles from "./PrinterTableList.module.css";

import { ReactComponent as EditIcon } from "./../assets/images/edit3.svg";
import { ReactComponent as DeleteIcon } from "./../assets/images/delete3.svg";

import { THEME_MODE } from "../constants/Theme";

let table_header_list = [
  "Name",
  "IP Address",
  "Port Number",
  "Display Name",
  "Assigned Courses",
  "Linked Floors",
  "Receipt Enabled",
  "Active",
];

const AntSwitch = styled(Switch)(({ theme, themeMode }) => ({
  width: 50,
  height: 24,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 45,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(-1px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(25px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#455A64",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 20,
    height: 20,
    borderRadius: 20 / 2,
    transition: theme.transitions.create(["width"], {
      duration: 100,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 50,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : themeMode == "light"
        ? "rgba(0,0,0,.25)"
        : "#333333",
    boxSizing: "border-box",
  },
}));

const PrinterTableList = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      color: theme_mode.tableHeaderText2,
      fontFamily: "InterMedium",
      fontSize: 14,
      border: 0,
    },
    [`&.${tableCellClasses.body}`]: {
      borderBottom: `${props.themeMode == "dark" ? 5 : 1}px solid ${
        theme_mode.tableBorder
      }`,
      color: theme_mode.tableRowText,
      fontFamily: "InterMedium",
      fontSize: 13,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme_mode.tableRow2,
    },
    "&:nth-of-type(even)": {
      backgroundColor: theme_mode.tableRow,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  let data = props.itemList;

  return (
    <TableContainer
      component={Paper}
      sx={{
        borderRadius: props.themeMode == "dark" ? 0 : 5,
        "&.MuiTableContainer-root": { backgroundColor: theme_mode.table },
      }}
      elevation={0}
    >
      {data.length ? (
        <Table aria-label="customized table">
          <TableHead sx={{ backgroundColor: theme_mode.tableHeader2 }}>
            <TableRow>
              {table_header_list.map((x, i) => {
                return (
                  <StyledTableCell key={i} sx={{ borderBottom: "none" }}>
                    <Box>{x}</Box>
                  </StyledTableCell>
                );
              })}
              <StyledTableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell>{item.name}</StyledTableCell>
                <StyledTableCell>{item.IPAddress}</StyledTableCell>
                <StyledTableCell>{item.portNumber}</StyledTableCell>
                <StyledTableCell>{item.displayName}</StyledTableCell>
                <StyledTableCell>
                  {props?.coursesList
                    ?.filter((x) => item?._idCourses?.includes(x?._id))
                    .map((x) => x?.name)
                    .join(", ")}
                </StyledTableCell>
                <StyledTableCell>
                  {props?.floorList
                    ?.filter((x) => item?._idFloors?.includes(x?._id))
                    .map((x) => x?.name)
                    .join(", ")}
                </StyledTableCell>
                <StyledTableCell>
                  {item.receiptEnabled ? "Yes" : "No"}
                </StyledTableCell>
                <StyledTableCell>
                  <AntSwitch
                    checked={props.activePrinter?._id == item._id}
                    onChange={() => props.onActivePrinter(item)}
                    inputProps={{ "aria-label": "ant design" }}
                    themeMode={props.themeMode}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <Box
                    className={styles.actionBox}
                    sx={{
                      path: {
                        stroke: theme_mode.iconBg2,
                      },
                    }}
                    pr={2}
                  >
                    <EditIcon
                      height={20}
                      width={20}
                      style={{ cursor: "pointer" }}
                      onClick={() => props.editItem(item)}
                    />
                    <DeleteIcon
                      height={20}
                      width={20}
                      style={{ marginLeft: 14, cursor: "pointer" }}
                      onClick={() => props.deleteItem(item)}
                    />
                  </Box>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      ) : null}
    </TableContainer>
  );
};

export default PrinterTableList;
