import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import Collapse from "@mui/material/Collapse";
import SortableList, { SortableItem } from "react-easy-sort";
import { arrayMoveImmutable } from "array-move";
import Button from "@mui/material/Button";

import styles from "./AddModifiers.module.css";

import { connect } from "react-redux";

import AddModifierItemModal from "./AddModifierItemModal";
import ModifierItemList from "./ModifierItemList";

import { THEME_MODE } from "../constants/Theme";

import { ReactComponent as UpIcon } from "../assets/images/up_white_arrow.svg";
import { ReactComponent as DownIcon } from "../assets/images/down_white_arrow.svg";
import { ReactComponent as BackBtnIcon } from "../assets/images/back_btn.svg";

const modifier_params = {
  _id: "add",
  required: false,
  addUpPrices: false,
  multiSelection: false,
  extraAllowed: false,
  defaultSelection: "",
  items: [],
};

const AddModifiers = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];
  const navigate = useNavigate();

  const [modifierList, setModifierList] = useState([]);
  const [modifierName, setModifierName] = useState("");
  const [modifierError, setModifierError] = useState("");
  const [addModifierItemModalVisible, setAddModifierItemModalVisible] =
    useState(false);
  const [modifierModalData, setModifierModalData] = useState(null);
  const [editItemData, setEditItemData] = useState(null);
  const [editModifierName, setEditModifierName] = useState("");
  const [editModifierError, setEditModifierError] = useState("");
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (props.modifiers.length) {
      setExpanded(true);
      let data = props.modifiers;
      if (props.modifierAddOption) {
        data = [...data, modifier_params];
      }
      setModifierList(data);
    } else {
      setExpanded(props.initialExpandModifier);
      if (props.modifierAddOption) {
        setModifierList([modifier_params]);
      }
    }
  }, [props.modifiers, props.modifierAddOption]);

  useEffect(() => {
    props.setModifierList(modifierList);
  }, [modifierList]);

  useEffect(() => {
    const { selectedModifier } = props;
    if (
      props.page === "modifier" &&
      selectedModifier?._id !== editItemData?._id
    ) {
      if (modifierList.length === 0) {
        return;
      }
      let list = [...modifierList];
      let index = list.findIndex((x) => x._id == selectedModifier._id);
      if (index >= 0) {
        list[index]["items"] = selectedModifier?.items ?? [];
        setEditItemData(selectedModifier);
        setEditModifierName(selectedModifier.header);
      }
      setModifierList(list);
    }
  }, [props.selectedModifier, modifierList.length]);

  const onActionBtnClick = (item, action, menu = null) => {
    let list = [...modifierList];
    let index = list.findIndex((x) => x._id == item._id);
    if (index >= 0) {
      if (action._id == "add_item") {
        setAddModifierItemModalVisible(true);
        setModifierModalData(item);
      } else if (action._id == "edit_item") {
        if (editItemData == null) {
          setEditItemData(item);
          setEditModifierName(item.header);
        } else {
          setEditModifierError("");
          if (editModifierName === "") {
            setEditModifierError("Modifier name required");
            return;
          }

          if (props.menuItems.length === 0 && props.page === "modifier") {
            setEditModifierError("Menu items required to link");
            return;
          }

          list[index]["header"] = editModifierName;
          setEditItemData(null);
          setEditModifierName("");
          props.onSaveModifier("edit", list[index]);
        }
      } else if (action._id === "save_modifier") {
        setModifierError("");
        if (modifierName === "") {
          setModifierError("Modifier name required");
          return;
        }
        if (item?.items?.length <= 0) {
          setModifierError("Items required");
          return;
        }

        if (props.menuItems.length === 0 && props.page === "modifier") {
          setModifierError("Menu items required to link");
          return;
        }
        list[index] = {
          ...item,
          _id: Date.now() + Math.random(),
          isNew: true,
          header: modifierName,
        };
        list.push({ ...modifier_params, items: [] });
        setModifierName("");
        props.onSaveModifier("add", list[index]);
      } else if (action._id == "delete") {
        if (props.page === "modifier") {
          props.onDeleteModifier(item);
        } else {
          list = list.filter((x) => x._id !== item._id);
        }
      } else if (action._id == "required") {
        list[index] = {
          ...item,
          required: !item.required,
        };
      } else if (action._id == "add_up_prices") {
        list[index] = {
          ...item,
          addUpPrices:
            typeof item?.addUpPrices != "undefined" ? !item?.addUpPrices : true,
        };
      } else if (action._id == "multi_selection") {
        list[index] = {
          ...item,
          multiSelection:
            typeof item?.multiSelection != "undefined"
              ? !item?.multiSelection
              : true,
        };
      } else if (action._id == "extra_allowed") {
        list[index] = {
          ...item,
          extraAllowed:
            typeof item?.extraAllowed != "undefined"
              ? !item?.extraAllowed
              : true,
        };
      } else if (action._id == "default") {
        list[index] = {
          ...item,
          defaultSelection:
            menu?._id == item?.defaultSelection ? "" : menu?._id,
        };
      }
      setModifierList(list);
    }
  };

  const onAddItemToModifier = (itemList) => {
    let item = modifierModalData;
    let list = [...modifierList];
    let index = list.findIndex((x) => x._id == item._id);
    if (index >= 0) {
      list[index] = {
        ...item,
        items: itemList,
      };
    }
    setModifierList(list);
    setModifierModalData(null);
    setAddModifierItemModalVisible(false);
  };

  const onRemoveMenuItem = (modifier, item) => {
    let list = [...modifierList];
    let index = list.findIndex((x) => x._id == modifier._id);
    if (index >= 0) {
      list[index].items = list[index].items.filter((x) => x._id != item._id);
      if (modifier?.defaultSelection == item._id) {
        list[index].defaultSelection = "";
      }
    }
    setModifierList(list);
  };

  const onSortEnd = (oldIndex, newIndex) => {
    setModifierList((array) => arrayMoveImmutable(array, oldIndex, newIndex));
  };

  const onSortEndMenuItem = (oldIndex, newIndex, modifier) => {
    let list = [...modifierList];
    let findIndex = list.findIndex((x) => x._id == modifier._id);
    if (findIndex >= 0) {
      list[findIndex]["items"] = arrayMoveImmutable(
        list[findIndex]["items"],
        oldIndex,
        newIndex
      );
    }
    setModifierList(list);
  };

  const onModifierClick = (item) => {
    props.onModifierClick(item);
  };

  const onDeleteAllModifier = () => {
    setModifierList(modifierList.filter((x) => x._id === "add"));
  };

  return (
    <Box className={styles.mainView}>
      <Box
        className={styles.titleView}
        style={{ backgroundColor: theme_mode.headerBg }}
        px={2}
        onClick={() => setExpanded(!expanded)}
      >
        {props?.showBackBtn ? (
          <BackBtnIcon
            width={30}
            height={30}
            className={`${styles?.["back-btn"]}`}
            onClick={props?.onBackBtnClick}
          />
        ) : null}
        <Typography
          className={styles.titleText}
          style={{ color: theme_mode.lightText }}
        >
          Modifiers
        </Typography>
        {props?.showDeleteAllBtn &&
        modifierList?.filter((x) => x._id !== "add")?.length ? (
          <Button
            type="button"
            className={`${styles["delete-btn"]}`}
            onClick={onDeleteAllModifier}
            sx={{ mr: 1 }}
          >
            Delete All
          </Button>
        ) : null}
        {expanded ? (
          <DownIcon width={25} height={25} />
        ) : (
          <UpIcon width={25} height={25} />
        )}
      </Box>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Box
          mt={1.5}
          className={styles.modifierList}
          style={props?.modifierListViewStyle}
        >
          <SortableList
            allowDrag={props.modifierEditable}
            onSortEnd={onSortEnd}
          >
            {modifierList.map((item, i) => {
              if (item?._id == "add") {
                return (
                  <ModifierItemList
                    key={i}
                    item={item}
                    themeMode={props.themeMode}
                    modifierName={modifierName}
                    setModifierName={setModifierName}
                    modifierError={modifierError}
                    editItemData={editItemData}
                    editModifierName={editModifierName}
                    setEditModifierName={setEditModifierName}
                    editModifierError={editModifierError}
                    initialShowHeaderOnly={false}
                    editOrEditItem={"edit_item"}
                    selectedModifier={null}
                    modifierEditable={true}
                    currency={props.currency}
                    onActionBtnClick={onActionBtnClick}
                    onRemoveMenuItem={onRemoveMenuItem}
                    onSortEndMenuItem={onSortEndMenuItem}
                    onModifierClick={onModifierClick}
                  />
                );
              }
              return (
                <SortableItem key={i}>
                  <Box>
                    <ModifierItemList
                      key={i}
                      item={item}
                      themeMode={props.themeMode}
                      modifierName={modifierName}
                      setModifierName={setModifierName}
                      modifierError={modifierError}
                      editItemData={editItemData}
                      editModifierName={editModifierName}
                      setEditModifierName={setEditModifierName}
                      editModifierError={editModifierError}
                      initialShowHeaderOnly={props.initialShowHeaderOnly}
                      editOrEditItem={props.editOrEditItem}
                      selectedModifier={props.selectedModifier}
                      modifierEditable={props.modifierEditable}
                      currency={props.currency}
                      onActionBtnClick={onActionBtnClick}
                      onRemoveMenuItem={onRemoveMenuItem}
                      onSortEndMenuItem={onSortEndMenuItem}
                      onModifierClick={onModifierClick}
                    />
                  </Box>
                </SortableItem>
              );
            })}
          </SortableList>
        </Box>
      </Collapse>
      <AddModifierItemModal
        themeMode={props.themeMode}
        modalVisible={addModifierItemModalVisible}
        modifierData={modifierModalData}
        restaurantId={props.restaurantId}
        categoryList={props.categoryList}
        menuList={props.menuList}
        onCancel={() => {
          setModifierModalData(null);
          setAddModifierItemModalVisible(false);
        }}
        onSubmit={onAddItemToModifier}
      />
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    themeMode: state.userData.themeMode,
  };
};

export default connect(mapStateToProps, null)(AddModifiers);
