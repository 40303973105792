import React from "react";
import { LineChart } from "@mui/x-charts/LineChart";
import { axisClasses } from '@mui/x-charts/ChartsAxis';

import { connect } from "react-redux";

const OrderCountGraph = ({ dataset }) => {
  return (
    <LineChart
      dataset={dataset}
      xAxis={[
        {
          scaleType: "band",
          dataKey: "date",
          label: "Timeline",
        },
      ]}
      yAxis={[{ label: 'Orders Count' }]}
      series={[{ dataKey: "count" }]}
      height={350}
      margin={{ left: 80, right: 60, top: 60, bottom: 60 }}
      grid={{ vertical: true, horizontal: true }}
      axisHighlight={{
        x: "none", // Or 'none', or 'band'
      }}
      sx={{
        [`& .${axisClasses.left} .${axisClasses.label}`]: {
          transform: 'translateX(-15px)',
          fill: '#FFF'
        },
        [`& .${axisClasses.bottom} .${axisClasses.label}`]: {
          transform: 'translateY(15px)',
          fill: '#FFF'
        },
        //change left yAxis label styles
        "& .MuiChartsAxis-left .MuiChartsAxis-tickLabel": {
          strokeWidth: "1",
          fill: "#fff",
        },
        // change all labels fontFamily shown on both xAxis and yAxis
        "& .MuiChartsAxis-tickContainer .MuiChartsAxis-tickLabel": {
          fontFamily: "InterMedium",
        },
        // change bottom label styles
        "& .MuiChartsAxis-bottom .MuiChartsAxis-tickLabel": {
          strokeWidth: "1",
          fill: "#fff",
        },
        // bottomAxis Line Styles
        "& .MuiChartsAxis-bottom .MuiChartsAxis-line": {
          stroke: "#AAA",
          strokeWidth: 1,
        },
        // leftAxis Line Styles
        "& .MuiChartsAxis-left .MuiChartsAxis-line": {
          stroke: "#AAA",
          strokeWidth: 1,
        },
      }}
    />
  );
};

export default OrderCountGraph;
