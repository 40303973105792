import React, { forwardRef, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import MapView from "../components/MapView";

import { THEME_COLOR, THEME_COLOR2, THEME_COLOR3 } from "../constants";

import "./../assets/css/custom.css";
import { THEME_MODE } from "../constants/Theme";

const COUNTRY_LIST = [
  "United Kingdom",
  "Ireland",
  "India",
  "Germany",
  "Turkey",
  "Saudi Arabia",
  "United Arab Emirates (UAE)",
  "Qatar",
];

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AddRestaurantLocation = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];
  const styles = styles1(theme_mode);

  const [firstLine, setFirstLine] = useState("");
  const [secondLine, setSecondLine] = useState("");
  const [postcode, setPostcode] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");
  const [msgAlert, setMsgAlert] = useState({
    open: false,
    message: "",
    msgType: "error",
  });

  const theme = createTheme({
    components: {
      MuiPopover: {
        styleOverrides: {
          root: {
            "& .MuiPaper-root.MuiMenu-paper.MuiPaper-elevation.MuiPaper-rounded.MuiPopover-paper":
              {
                backgroundColor: theme_mode.card,
                maxHeight: 300,
              },
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            "& .MuiSvgIcon-root": {
              color: theme_mode.iconBg2,
            },
          },
        },
      },
    },
    palette: {
      gray: {
        main: THEME_COLOR2,
      },
    },
  });

  useEffect(() => {
    setFirstLine(props.firstLine);
    setSecondLine(props.secondLine);
    setPostcode(props.postcode);
    setCity(props.city);
    setLat(props.lat);
    setLng(props.lng);
    setCountry(props.country);
  }, [props]);

  const onCloseAlertMsg = () => {
    setMsgAlert({ open: false, message: "", msgType: "error" });
  };

  const onPrevBtnClick = () => {
    props.resLocation("", "prev");
  };

  const onNextBtnClick = (e) => {
    e.preventDefault();
    if (/[^0-9a-zA-Z ]/g.test(postcode)) {
      setMsgAlert({
        open: true,
        message: "Invalid Postcode",
        msgType: "error",
      });
      return;
    }

    if (
      floatNumCheck(lat) == "" ||
      floatNumCheck(lat) == "+" ||
      floatNumCheck(lat) == "-"
    ) {
      setMsgAlert({
        open: true,
        message: "Invalid Latitude",
        msgType: "error",
      });
      return;
    }

    if (
      floatNumCheck(lng) == "" ||
      floatNumCheck(lng) == "+" ||
      floatNumCheck(lng) == "-"
    ) {
      setMsgAlert({
        open: true,
        message: "Invalid Longitude",
        msgType: "error",
      });
      return;
    }

    let data = {
      firstLine: firstLine,
      secondLine: secondLine,
      postcode: postcode,
      city: city,
      country: country,
      lat: lat,
      lng: lng,
    };
    props.resLocation(data, "next");
  };

  const floatNumCheck = (val) => {
    val = val.toString();
    let sign = "";
    if (val.length >= 1) {
      if (val[0] == "+" || val[0] == "-") {
        sign = val[0];
      }
    }
    val = val.replace(/[^0-9\.]/g, "");
    if (val.split(".").length > 2) {
      val = val.replace(/\.+$/, "");
    }
    return sign + val;
  };

  const handlerOnChange = (type, value) => {
    if (type === "country") {
      setCountry(value);
    }
  };

  return (
    <Grid component="form" autoComplete="off" onSubmit={onNextBtnClick}>
      <Grid
        container
        direction="row"
        item
        xs={12}
        justifyContent="space-between"
      >
        <Grid item xs={12} md={5.3} mt={2}>
          <Grid>
            <ThemeProvider theme={theme}>
              <TextField
                required
                focused
                fullWidth
                color="gray"
                id="firstLine"
                label="First Line"
                variant="standard"
                value={firstLine}
                onChange={(e) => setFirstLine(e.target.value)}
                InputLabelProps={{
                  style: { color: THEME_COLOR3, fontFamily: "InterRegular" },
                }}
                inputProps={{
                  autoComplete: "none",
                  sx: {
                    fontSize: 14,
                    fontFamily: "InterSemiBold",
                    color: theme_mode.text,
                  },
                }}
              />
            </ThemeProvider>
          </Grid>

          <Grid mt={4}>
            <ThemeProvider theme={theme}>
              <TextField
                required
                focused
                fullWidth
                color="gray"
                id="secondLine"
                label="Second Line"
                variant="standard"
                value={secondLine}
                onChange={(e) => setSecondLine(e.target.value)}
                InputLabelProps={{
                  style: { color: THEME_COLOR3, fontFamily: "InterRegular" },
                }}
                inputProps={{
                  autoComplete: "none",
                  sx: {
                    fontSize: 14,
                    fontFamily: "InterSemiBold",
                    color: theme_mode.text,
                  },
                }}
              />
            </ThemeProvider>
          </Grid>

          <Grid container direction="row" justifyContent="space-between">
            <Grid item xs={12} md={5.5} mt={4}>
              <ThemeProvider theme={theme}>
                <TextField
                  required
                  focused
                  fullWidth
                  color="gray"
                  id="city"
                  label="City"
                  variant="standard"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  InputLabelProps={{
                    style: { color: THEME_COLOR3, fontFamily: "InterRegular" },
                  }}
                  inputProps={{
                    autoComplete: "none",
                    sx: {
                      fontSize: 14,
                      fontFamily: "InterSemiBold",
                      color: theme_mode.text,
                    },
                  }}
                />
              </ThemeProvider>
            </Grid>
            <Grid item xs={12} md={5.5} mt={3}>
              <label className="restaurant-label" style={styles.labelText}>
                Country *
              </label>
              <ThemeProvider theme={theme}>
                <Select
                  required
                  fullWidth
                  disableUnderline
                  sx={styles.selectBox}
                  color="gray"
                  variant="standard"
                  value={country}
                  onChange={(event) =>
                    handlerOnChange("country", event.target.value)
                  }
                >
                  {COUNTRY_LIST.map((item, index) => (
                    <MenuItem
                      sx={styles.menuItemText}
                      divider={index < COUNTRY_LIST.length - 1}
                      key={index}
                      value={item}
                    >
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </ThemeProvider>
            </Grid>
          </Grid>

          <Grid mt={4}>
            <ThemeProvider theme={theme}>
              <TextField
                required
                focused
                fullWidth
                color="gray"
                id="postcode"
                label="Postcode"
                variant="standard"
                value={postcode}
                onChange={(e) =>
                  setPostcode(e.target.value.replace(/[^0-9a-zA-Z ]/g, ""))
                }
                InputLabelProps={{
                  style: { color: THEME_COLOR3, fontFamily: "InterRegular" },
                }}
                inputProps={{
                  autoComplete: "none",
                  sx: {
                    fontSize: 14,
                    fontFamily: "InterSemiBold",
                    color: theme_mode.text,
                  },
                }}
              />
            </ThemeProvider>
          </Grid>

          <Grid container direction="row" justifyContent="space-between">
            <Grid item xs={12} md={5.5} mt={4}>
              <ThemeProvider theme={theme}>
                <TextField
                  required
                  focused
                  fullWidth
                  color="gray"
                  id="latitude"
                  label="Latitude"
                  variant="standard"
                  value={lat.toString()}
                  InputLabelProps={{
                    style: { color: THEME_COLOR3, fontFamily: "InterRegular" },
                  }}
                  inputProps={{
                    autoComplete: "none",
                    sx: {
                      fontSize: 14,
                      fontFamily: "InterSemiBold",
                      color: theme_mode.text,
                    },
                  }}
                  onChange={(event) =>
                    setLat(floatNumCheck(event.target.value))
                  }
                />
              </ThemeProvider>
            </Grid>
            <Grid item xs={12} md={5.5} mt={4}>
              <ThemeProvider theme={theme}>
                <TextField
                  required
                  focused
                  fullWidth
                  color="gray"
                  id="longitude"
                  label="Longitude"
                  variant="standard"
                  value={lng.toString()}
                  InputLabelProps={{
                    style: { color: THEME_COLOR3, fontFamily: "InterRegular" },
                  }}
                  inputProps={{
                    autoComplete: "none",
                    sx: {
                      fontSize: 14,
                      fontFamily: "InterSemiBold",
                      color: theme_mode.text,
                    },
                  }}
                  onChange={(event) =>
                    setLng(floatNumCheck(event.target.value))
                  }
                />
              </ThemeProvider>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={5.3} mt={2}>
          <Grid
            sx={{
              borderRadius: 3,
              overflow: "hidden",
              width: "100%",
              height: "100%",
              minHeight: 200,
              position: "relative",
            }}
          >
            <MapView
              center={{
                lat: lat == "" ? 0 : parseFloat(lat),
                lng: lng == "" ? 0 : parseFloat(lng),
              }}
              zoom={15}
              address={""}
              restName={""}
              type={"location"}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        mt={7}
        mb={1}
      >
        <Typography onClick={() => onPrevBtnClick()} sx={styles.btnText}>
          Previous
        </Typography>
        <Button
          type="submit"
          sx={{
            backgroundColor: theme_mode.theme,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            textTransform: "capitalize",
            color: theme_mode.text,
            fontFamily: "InterBold",
            fontSize: 13,
            borderRadius: 1,
            marginLeft: 2,
            width: 100,
            height: 35,
            "&:hover": {
              backgroundColor: theme_mode.theme + " !important",
            },
          }}
        >
          Next
        </Button>
      </Grid>

      <Snackbar
        open={msgAlert.open}
        autoHideDuration={6000}
        onClose={onCloseAlertMsg}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Alert
          onClose={onCloseAlertMsg}
          severity={msgAlert.msgType}
          sx={{ width: "100%" }}
        >
          {msgAlert.message}
        </Alert>
      </Snackbar>
    </Grid>
  );
};

export default AddRestaurantLocation;

const styles1 = (Theme) => ({
  labelText: {
    color: Theme.inputLabel,
    fontFamily: "InterRegular",
  },
  selectBox: {
    color: Theme.inputVal,
    borderBottom: "2px solid " + Theme.borderBottom2,
    fontSize: 14,
    fontFamily: "InterSemiBold",
  },
  menuItemText: {
    fontSize: 13,
    fontFamily: "InterSemiBold",
    color: Theme.inputVal,
    "&.MuiMenuItem-divider": {
      borderBottom: "1px solid #DDD",
    },
    "&:hover": {
      backgroundColor: Theme.inputBg2,
      color: Theme.text,
    },
    "&.Mui-selected:hover": {
      backgroundColor: Theme.inputBg2,
      color: Theme.text,
    },
    "&.Mui-selected": {
      backgroundColor: Theme.inputBg5,
      color: Theme.text,
    },
  },
  btnText: {
    backgroundColor: Theme.theme,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    fontFamily: "InterBold",
    fontSize: 13,
    color: Theme.text,
    borderRadius: 1,
    width: 100,
    height: 35,
  },
});
