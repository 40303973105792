import React, { useState, useEffect } from "react";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import styles from "./AddFloorNameModal.module.css";

import * as Api from "../api";

import SettingTextField from "./SettingTextField";
import AlertMsg from "./AlertMsg";
import Loader from "./Loader";

import { THEME_MODE } from "../constants/Theme";
import { IS_LARGE_SCREEN } from "../constants";

import { ReactComponent as CloseIcon } from "../assets/images/close_black.svg";

const BTN_HEIGHT = IS_LARGE_SCREEN ? 90 : 60;

const AddFloorNameModal = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];

  const [isLoading, setIsLoading] = useState(true);
  const [floorName, setFloorName] = useState("");
  const [error, setError] = useState(null);
  const [msgAlert, setMsgAlert] = useState({
    open: false,
    message: "",
    msgType: "error",
  });

  useEffect(() => {
    setIsLoading(false);
    if (props.editData) {
      let data = props.editData;
      setFloorName(data.name);
    } else {
      setFloorName("");
    }
    setMsgAlert({
      open: false,
      message: "",
      msgType: "error",
    })
  }, [props.editData]);

  useEffect(() => {
    if (!props.modalVisible) {
      setIsLoading(false);
      setFloorName("");
    }
  }, [props.modalVisible]);

  const onSubmit = () => {
    let err = null;
    if (floorName.trim() == "") {
      err = { floorName: "Name is required" };
    }

    setError(err);
    if (err != null) {
      return;
    }

    createFloor();
  };

  const createFloor = () => {
    setIsLoading(true);
    const floorId = props.editData?._id ?? null;
    const data1 = {
      name: floorName,
    };
    let datas = JSON.stringify(data1);
    Api.createRestaurantFloors(datas, props.restaurantId, floorId).then(
      (response) => {
        if (response.success) {
          if (floorId) {
            props.onSubmit({
              ...props.editData,
              name: floorName,
            });
          } else {
            props.onSubmit({
              ...response.data,
              tableDetails: [],
            });
          }
        } else {
          setIsLoading(false);
          setMsgAlert({ open: true, message: response.msg, msgType: "error" });
        }
      }
    );
  };

  return (
    <Modal
      className={styles.modelView}
      open={props.modalVisible}
      onClose={() => props.onClose()}
    >
      <Grid
        item
        container
        className={styles.mainView}
        md={4}
        xs={12}
        sx={{
          backgroundColor: theme_mode.modal,
          "&:focus": {
            outline: "none",
          },
          m: { md: "20px", xs: "10px" },
          width: { md: "60%", xs: "100%" },
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Box
            className={styles.modalHeaderView}
            sx={{ backgroundColor: theme_mode.modalHeaderBg }}
            px={2}
          >
            <Typography
              className={styles.modalHeaderText}
              sx={{ color: theme_mode.lightText }}
            >
              {props.editData ? "Edit" : "Add"} Floor
            </Typography>
            <Box className="cursor-pointer" onClick={() => props.onClose()}>
              <CloseIcon width={40} height={40} />
            </Box>
          </Box>
          {isLoading ? (
            <Loader
              height={{
                height: 100,
              }}
            />
          ) : (
            <Box p={2}>
              <Box>
                <SettingTextField
                  page={"addPrinter"}
                  value={floorName}
                  setValue={setFloorName}
                  backgroundColor={theme_mode.inputBg2}
                  label={"Floor Name"}
                  placeholder={"Enter here"}
                  required={true}
                  variant={"filled"}
                  error={error?.floorName}
                />
              </Box>
            </Box>
          )}
          {isLoading ? null : (
            <Box className={styles.btnView}>
              <Box
                onClick={() => props.onClose()}
                className={styles.submitBtn}
                style={{
                  backgroundColor: theme_mode.redBg,
                  height: BTN_HEIGHT,
                }}
              >
                <Typography
                  className={styles.submitBtnText}
                  style={{ color: theme_mode.lightText }}
                >
                  CANCEL
                </Typography>
              </Box>
              <Box
                onClick={onSubmit}
                className={styles.submitBtn}
                style={{
                  backgroundColor: theme_mode.greenBg,
                  height: BTN_HEIGHT,
                }}
              >
                <Typography
                  className={styles.submitBtnText}
                  style={{ color: theme_mode.lightText }}
                >
                  {props.editData ? "UPDATE" : "SUBMIT"}
                </Typography>
              </Box>
            </Box>
          )}
          <AlertMsg
            msgAlert={msgAlert}
            onCloseAlertMsg={() =>
              setMsgAlert({ open: false, message: "", msgType: "error" })
            }
          />
        </Box>
      </Grid>
    </Modal>
  );
};

export default AddFloorNameModal;
